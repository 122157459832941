import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../Assets/footer_logo.png";
import "./Header_meta.css";
import { NavHashLink } from "react-router-hash-link";
import { FaWallet } from "react-icons/fa6";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { MdRocketLaunch } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";


export default function Header_meta({ flag }) {
  const scrollToTokenomics = () => {
    const tokenomicsSection = document.getElementById("Tokenomics");
    if (tokenomicsSection) {
      window.scrollTo({
        top: tokenomicsSection.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();
  const { open } = useWeb3Modal();



  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary navbg">
        <Container>
          <Navbar.Brand className="main_logo d-flex gap-1 align-items-ceter">
            <Link to="/">
              <div className="d-flex  align-items-center gap-2">
                {" "}
                <img src={logo} alt="" />{" "}
                <span
                  className={
                    flag == "true"
                      ? "header_links site_font"
                      : "header_links1 site_font"
                  }
                >
                  Infinite Protocol
                </span>
              </div>
            </Link>
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
          <span onClick={() => setShow(!show)} className="d-block d-lg-none">
            {show ? (
              <>
                <RxCross2
                  className={
                    flag == "true"
                      ? " text-white site_font"
                      : "text-dark site_font"
                  }
                />{" "}
              </>
            ) : (
              <>
                <AiOutlineMenu
                  className={
                    flag == "true"
                      ? " text-white site_font"
                      : "text-dark site_font"
                  }
                />
              </>
            )}
          </span>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className={show ? "show" : ""}
          >
            <Nav className="m-auto">
              {" "}
              <Nav.Link
                href="#"
                className={
                  flag == "true"
                    ? " header_links site_font"
                    : "header_links1 site_font"
                }
              >
                <Link to="/buy_token" className="text-decoration-none">
                  Buy Now{" "}
                </Link>
              </Nav.Link>
              <Link to="https://heyzine.com/flip-book/3e3a434fe0.html" target="blank" className="text-decoration-none">
                {" "}
                <Nav.Link
                  href="https://heyzine.com/flip-book/3e3a434fe0.html"  target="blank"
                  className={
                    flag == "true"
                      ? "header_links site_font"
                      : "header_links1 site_font"
                  }
                >
                  Pitch Deck
                </Nav.Link>{" "}
              </Link>{" "}
              <Nav.Link
                href="#"
                className={
                  flag == "true"
                    ? "header_links site_font"
                    : "header_links1 site_font"
                }
              >
                {/* <Link to="/" className="text-decoration-none"  onClick={scrollToTokenomics}> */}
                <NavHashLink
                  smooth
                  to="/#Tokenomics"

                >
                  Tokenomics
                </NavHashLink>

                {/* </Link> */}
              </Nav.Link>{" "}
              {/* <Link to="/" className="text-decoration-none">
                {" "}
                <Nav.Link href="#" className={flag=="true" ? "header_links":"header_links1"}>
                  Whitepaper
                </Nav.Link>{" "}
              </Link> */}
              {/* <Link to="/" className="text-decoration-none"> */}
              <Nav.Link
                className={
                  flag == "true"
                    ? "header_links site_font"
                    : "header_links1 site_font"
                }
              >
                <NavHashLink
                  smooth
                  to="/#Team"

                >
                  Team
                </NavHashLink>
              </Nav.Link>{" "}
              {/* </Link> */}
              {/* <Link to="#RoadMap" className="text-decoration-none"> */}{" "}
              <Nav.Link
                href="#RoadMap"
                className={
                  flag == "true"
                    ? "header_links site_font"
                    : "header_links1 site_font"
                }
              >
                RoadMap
              </Nav.Link>{" "}
              {/* </Link>{" "} */}
              <Nav.Link
                className={
                  flag == "true"
                    ? "header_links site_font"
                    : "header_links1 site_font"
                }
              >
                <Link
                  to="/Refferal_page"
                  className={
                  flag == "true"
                    ? "header_links site_font"
                    : "header_links1 site_font"
                }

                >
                  Referral Link
                </Link>
              </Nav.Link>
            </Nav>
            <Nav>
              <div className="d-flex flex-column flex-lg-row justify-content-center gap-2">
                <Link
                  to="/LogIn"
                  className="text-decoration-none"
                  style={{ color: flag == "true" ? "#fff" : "#fff" }}
                >
                  <button
                    className={
                      flag == "true" ? "get_start_btn " : "get_start_btn "
                    }
                  >
                    <MdRocketLaunch />
                    Launch App
                  </button>
                </Link>
                <button
                  className="get_start_btn d-flex align-items-center"
                  onClick={() =>{
                      address
                      ? chain?.id == chains[0]?.id
                        ? open()
                        : switchNetwork?.(chains[0]?.id)
                      : open()

                  }


                  }
                >
                  {address ? (
                    chain?.id == chains[0]?.id || chain?.id == chains[1]?.id ? (
                      address ? (
                        <>
                          {`${address.substring(0, 6)}...${address.substring(
                            address.length - 4
                          )}`}
                        </>
                      ) : (
                        <>
                          <FaWallet className="mx-1" />{" "}
                          <span className="mx-1">Connect Wallet</span>
                        </>
                      )
                    ) : (
                      "Switch NetWork"
                    )
                  ) : (
                    <>
                      <FaWallet className="mx-1" />{" "}
                      <span className="mx-1">Connect Wallet</span>
                    </>
                  )}
                </button>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
