import React from "react";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

const PieChart = () => {
  // Sample data for the Doughnut chart
  const data = {
    labels: ["Bitcoin", "Ripple", "Litecoin"],
    datasets: [
      {
        label: "My First Dataset",
        data: [12, 19, 3],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div>
      {/* <h2>Pie Chart Example</h2> */}
      <Doughnut data={data} style={{height:"250px",width:"200px"}}  />
    </div>
  );
};

export default PieChart;
