import React, { useEffect, useState } from "react";
import Header from "../Header_meta/Header_meta";
import "./Token_page.css";
import Eth from "../Assets/Eth.png";
import usdt from "../Assets/usdt.svg";
import usdc from "../Assets/usdc.png";
import busd from "../Assets/busd.png";
import { FaCheck, FaCopy } from "react-icons/fa";
import logo from "../Assets/site_logo.png";
// import * as React from 'react';
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import Web3 from "web3";
import {
  PresaleEthV2_ABI,
  PresaleEthV2_Address,
  PresaleEthV2_Token_ABI,
  PresaleEthV2_Token_Address,
  USDC_Eth_Abi,
  USDC_Eth_Address,
  USDT_Abi,
  USDT_Address,
} from "../../utilies/constant";
import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";
import toast from "react-hot-toast";
import { FaPersonWalkingDashedLineArrowRight } from "react-icons/fa6";

export default function Token_page() {
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const [isActive, setisActive] = useState(0);
  const [Token_Balance, setToken_Balance] = useState(0);
  const [get_userValue, setget_userValue] = useState("");
  const [sliderValue, setSliderValue] = useState(14);
  const [bounsShow, setbounsShow] = useState(0);
  const [months, setmonths] = useState(0);
  const [stagePrice_show, setstagePrice_show] = useState(0);
  const [purchasedETH, setpurchasedETH] = useState(0);
  const [Purchased_show, setPurchased_show] = useState(0);
  const [spinner, setspinner] = useState(false);
  const [ethToUSDT, setethToUSDT] = useState(0);
  const [check_QuantityBonus, setcheck_QuantityBonus] = useState(0);
  const [spendBonus, setspendBonus] = useState(0);
  const [approveCheck, setapproveCheck] = useState(false);


  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let refValue = urlParams.get("ref");



  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    // console.log("newValue", newValue);
    setSliderValue(newValue);
    if (newValue == 14) {
      setbounsShow(0);
      setmonths(0);
    } else if (newValue == 28) {
      setbounsShow(8);
      setmonths(6);
    } else if (newValue == 42) {
      setbounsShow(12);
      setmonths(12);
    } else if (newValue == 56) {
      setbounsShow(20);
      setmonths(18);
    } else if (newValue == 70) {
      setbounsShow(25);
      setmonths(24);
    } else if (newValue == 84) {
      setmonths(36);
      setbounsShow(30);
    } else if (newValue == 98) {
      setmonths(48);
      setbounsShow(40);
    }
  };

  const webSupply = new Web3("https://bsc-rpc.publicnode.com");

  const BalanceOf = async () => {
    let contractOf_USDT = new webSupply.eth.Contract(USDT_Abi, USDT_Address);
    let contractOf_USDC = new webSupply.eth.Contract(
      USDC_Eth_Abi,
      USDC_Eth_Address
    );
    let contractOf = new webSupply.eth.Contract(
      PresaleEthV2_ABI,
      PresaleEthV2_Address
    );
    let stagePrice = 0;
    if (address) {
      if (address && isActive == 2) {
        let balance = await contractOf_USDC.methods.balanceOf(address).call();
        stagePrice = await contractOf.methods.stagePrice().call();
        stagePrice = webSupply.utils.fromWei(stagePrice.toString());
        setstagePrice_show(stagePrice);
        balance =webSupply.utils.fromWei(balance.toString());
        // balance = webSupply.utils.fromWei(balance.toString());
        setToken_Balance(balance);
      } else if (address && isActive == 1) {
        let balance = await contractOf_USDT?.methods.balanceOf(address).call();
        // balance = webSupply.utils.fromWei(balance.toString());
        balance =webSupply.utils.fromWei(balance.toString());
        setToken_Balance(balance);
        stagePrice = await contractOf.methods.stagePrice().call();
        stagePrice = webSupply.utils.fromWei(stagePrice.toString());
        setstagePrice_show(stagePrice);
      } else {
        // console.log(webSupply.eth.getBalance('0x4113ccD05D440f9580d55B2B34C92d6cC82eAB3c'));
        let balance = await webSupply.eth.getBalance(address);
        balance = webSupply.utils.fromWei(balance.toString());
        let getLatestPrice = await contractOf.methods.getLatestPrice().call();
        getLatestPrice = webSupply.utils.fromWei(getLatestPrice.toString());
        setethToUSDT(getLatestPrice);
        setToken_Balance(parseFloat(balance).toFixed(3));
        stagePrice = await contractOf.methods.stagePrice().call();
        stagePrice = webSupply.utils.fromWei(stagePrice.toString());
        setstagePrice_show(stagePrice / getLatestPrice);
        setpurchasedETH(stagePrice);
      }
      let check_Value =
        get_userValue && stagePrice != 0
          ? isActive !== 0
            ? Number(get_userValue) / Number(stagePrice)
            : Number(Number(ethToUSDT) * Number(get_userValue)) /
              Number(stagePrice)
          : 0;
      let checkTokenQuantityBonus = await contractOf.methods
        .checkTokenQuantityBonus(parseInt(check_Value))
        .call();
      checkTokenQuantityBonus = webSupply.utils.fromWei(
        checkTokenQuantityBonus.toString()
      );
      setcheck_QuantityBonus(checkTokenQuantityBonus);
      if (isActive == 0) {
        if (
          Number(ethToUSDT) * Number(get_userValue) >= Number(100) &&
          Number(ethToUSDT) * Number(get_userValue) < Number(250)
        ) {
          setspendBonus(4);
        } else if (
          Number(ethToUSDT) * Number(get_userValue) >= Number(250) &&
          Number(ethToUSDT) * Number(get_userValue) < Number(500)
        ) {
          setspendBonus(6);
        } else if (
          Number(ethToUSDT) * Number(get_userValue) >= Number(500) &&
          Number(ethToUSDT) * Number(get_userValue) < Number(1000)
        ) {
          setspendBonus(8);
        } else if (
          Number(ethToUSDT) * Number(get_userValue) >= Number(1000) &&
          Number(ethToUSDT) * Number(get_userValue) < Number(2500)
        ) {
          setspendBonus(10);
        } else if (Number(ethToUSDT) * Number(get_userValue) >= Number(2500)) {
          setspendBonus(12);
        } else {
          setspendBonus(0);
        }
      } else {
        if (
          Number(get_userValue) >= Number(100) &&
          Number(get_userValue) < Number(250)
        ) {
          setspendBonus(4);
        } else if (
          Number(get_userValue) >= Number(250) &&
          Number(get_userValue) < Number(500)
        ) {
          setspendBonus(6);
        } else if (
          Number(get_userValue) >= Number(500) &&
          Number(get_userValue) < Number(1000)
        ) {
          setspendBonus(8);
        } else if (
          Number(get_userValue) >= Number(1000) &&
          Number(get_userValue) < Number(2500)
        ) {
          setspendBonus(10);
        } else if (Number(get_userValue) >= Number(2500)) {
          setspendBonus(12);
        } else {
          setspendBonus(0);
        }
      }
    } else {
    }
  };

  useEffect(() => {
    BalanceOf();
  }, [isActive, get_userValue]);

  // console.log("ethToUSDT", ethToUSDT);

  const handleSubmit = async () => {
    try {
      setspinner(true);
      let value = Number(get_userValue) * Number(1000000000000000000);

      if (isActive != 0) {
        // if (months == 0) {
        //   toast.error("Choose Vesting Bonus First!");
        //   setspinner(false);
        // } else {
        if (get_userValue == 0) {
          toast.error("Please Enter Amount First!");
          setspinner(false);
        } else {
          let bonusAmount =
            get_userValue && stagePrice_show != 0
              ? parseFloat(
                  Number(get_userValue) / Number(stagePrice_show)
                ).toFixed(0)
              : 0;
          const { request } = await prepareWriteContract({
            address:
              isActive == 2
                ? USDC_Eth_Address
                : isActive == 1
                ? USDT_Address
                : PresaleEthV2_Token_Address,
            abi:
              isActive == 2
                ? USDC_Eth_Abi
                : isActive == 1
                ? USDT_Abi
                : PresaleEthV2_Token_ABI,
            functionName: "approve",
            args: [PresaleEthV2_Address, value.toString()],
            account: address,
          });
          const { hash } = await writeContract(request);
          const data = await waitForTransaction({
            hash,
          });
          // setspinner(false);
          setTimeout(async () => {
            toast.success("Approve Confirmed");
            setapproveCheck(true);

            if (refValue == null) {
              refValue = "0x0000000000000000000000000000000000000000";
            }
            bonusAmount = Number(bonusAmount) - Number(1);
            const { request } = await prepareWriteContract({
              address: PresaleEthV2_Address,
              abi: PresaleEthV2_ABI,
              functionName: isActive == 2 ? "buyWithUSDC" : "buyWithUSDT",
              args: [bonusAmount.toString(), months.toString(), refValue],
              account: address,
            });
            const { hash } = await writeContract(request);
            const data = await waitForTransaction({
              hash,
            });
            toast.success(`Transtion Successfull.`);
            setspinner(false);
          }, 1000);
        }
      } else {
        // if (months == 0) {
        //   toast.error("Choose Vesting Bonus First!");
        //   setspinner(false);
        // } else {
        if (get_userValue == 0) {
          toast.error("Please Enter Amount First!");
          setspinner(false);
        } else {
          let bonusAmount =
            get_userValue && stagePrice_show != 0
              ? parseFloat(
                  Number(Number(ethToUSDT) * Number(get_userValue)) /
                    Number(purchasedETH)
                ).toFixed(0)
              : 0;
          // console.log("bonusAmount",bonusAmount,"value",value);
          if (refValue == null) {
            refValue = "0x0000000000000000000000000000000000000000";
          }

          bonusAmount = Number(bonusAmount) - Number(1);
          const { request } = await prepareWriteContract({
            address: PresaleEthV2_Address,
            abi: PresaleEthV2_ABI,
            functionName: "buyWithCoin",
            args: [bonusAmount.toString(), months.toString(), refValue],
            value: webSupply.utils.toWei(get_userValue.toString()),
            account: address,
          });
          const { hash } = await writeContract(request);
          const data = await waitForTransaction({
            hash,
          });
          toast.success(`Transtion Successfull`);
          setspinner(false);
        }
      }
    } catch (error) {
      console.log(error);
      setspinner(false);
    }
  };
  const USDTapproveSubmit = async () => {
    try {
      setspinner(true);
      let value = Number(0);
      const { request } = await prepareWriteContract({
        address: USDT_Address,
        PresaleEthV2_Token_Address,
        abi: USDT_Abi,
        PresaleEthV2_Token_ABI,
        functionName: "approve",
        args: [PresaleEthV2_Address, value.toString()],
        account: address,
      });
      const { hash } = await writeContract(request);
      const data = await waitForTransaction({
        hash,
      });
      setspinner(false);
      setapproveCheck(true);
    } catch (error) {
      console.log(error);
      setspinner(false);
    }
  };

  return (
    <div className="token_page_with_new_bg">
      <div className="overlay1"></div>
      <div>
        {/* <Header /> */}
        <div className="home_content">
          <h1 className="text-center sfss position-relative z_upper_full site_font">
            Exchange $INFAI Tokens
          </h1>
        </div>
      </div>

      <div className=" whitE_section">
        <div className="row  mx-0   justify-content-center">
          <div className="col-md-10 z_upper_full">
            <div className="token_info">
              <div className="d-flex mb_view align-items-center justify-content-between">
                <div className="">
                  <div className="d-flex cn_ssm   gap-2 align-items-center">
                    {isActive == 0 ? (
                      <>
                        <img src={Eth} alt="Ethereum" className="price_pics" />{" "}
                      </>
                    ) : isActive == 1 ? (
                      <>
                        {" "}
                        <img src={usdt} alt="USDT" className="price_pics" />
                      </>
                    ) : (
                      <>
                        <img src={usdc} className=" price_pics" alt="USDC" />
                      </>
                    )}
                    <h1 className="chain_balance mb-0">
                      {isActive == 0
                        ? "BNB "
                        : isActive == 1
                        ? "USDT "
                        : "USDC "}
                      balance : {Token_Balance}
                    </h1>
                  </div>
                </div>
                <div className=" d-flex gap-2">
                  <div
                    className="inner_chain d-flex cn_ssm gap-1 mt-2 mt-md-0 gap-md-2 align-items-center"
                    onClick={() => setisActive(0)}
                    style={{
                      backgroundColor: isActive == 0 ? "#fff" : "",
                      color: isActive == 0 ? "#000" : "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <img src={Eth} alt="" />
                    BNB
                  </div>
                  <div
                    className="inner_chain d-flex cn_ssm mt-2 mt-md-0 gap-1 gap-md-2 align-items-center"
                    onClick={() => setisActive(1)}
                    style={{
                      backgroundColor: isActive == 1 ? "#fff" : "",
                      color: isActive == 1 ? "#000" : "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <img src={usdt} alt="" />
                    USDT
                  </div>

                  <div
                    className="inner_chain cn_ssm d-flex gap-1 mt-2 mt-md-0 gap-md-2 align-items-center"
                    onClick={() => setisActive(2)}
                    style={{
                      backgroundColor: isActive == 2 ? "#fff" : "",
                      color: isActive == 2 ? "#000" : "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <img src={usdc} className="usdc" alt="" />
                    USDC
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="change_wallter">
                  <p>You can simply change your network</p>
                  <button
                    className="mt-2"
                    onClick={() =>
                    {
                      address
                        ? chain?.id == chains[0]?.id
                          ? open()
                          : switchNetwork?.(chains[0]?.id)
                        : open()
                    }
                    }
                  >
                    {address ? (
                      chain?.id == chains[0]?.id ||
                      chain?.id == chains[1]?.id ? (
                        address ? (
                          <>
                            {`${address?.substring(
                              0,
                              6
                            )}...${address?.substring(address.length - 4)}`}
                          </>
                        ) : (
                          <>Connect Wallet</>
                        )
                      ) : (
                        "Switch NetWork"
                      )
                    ) : (
                      <>Connect Wallet</>
                    )}
                  </button>
                </div>
                <div className="text-white d-flex  align-items-center gap-1">
                  {address
                    ? `${PresaleEthV2_Address?.substring(
                        0,
                        6
                      )}...${PresaleEthV2_Address?.substring(
                        PresaleEthV2_Address.length - 4
                      )}`
                    : `${PresaleEthV2_Address?.substring(
                        0,
                        6
                      )}...${PresaleEthV2_Address?.substring(
                        PresaleEthV2_Address.length - 4
                      )}`}
                  <FaCopy />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row  mx-0   justify-content-center">
          <div className="col-md-10 z_upper_full  mt-5 jhkk">
            <div className="token_info">
              <div className="row m-0 align-items-center jhkk">
                <div className="col-lg-3 col-md-6 cod-sm-6">
                  <div className=" mb_centr ">
                    <div className="about_token mb-3">Name</div>
                    <div className="d-flex mbll_align gap-2 align-items-center">
                      <img src={logo} className="price_pics" alt="" />
                      <h1 className="chain_balance mb-0">INFAI Token</h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="mb_centr mt-3 ">
                    <div className="about_token mb-3">Price</div>

                    <div className="d-flex mbll_align text-white fw-bold gap-2 align-items-center">
                      1
                      <img src={logo} className="price_pics" alt="" />=
                      {isActive == 2 ? (
                        <>
                          <img src={usdc} className="price_pics" alt="" />
                          {stagePrice_show} USDC
                        </>
                      ) : isActive == 1 ? (
                        <>
                          <img src={usdt} className="price_pics" alt="" />
                          {stagePrice_show} USDT
                        </>
                      ) : (
                        <>
                          <img src={Eth} className="price_pics" alt="" />{" "}
                          {parseFloat(stagePrice_show).toFixed(7)} BNB
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 d-none d-md-flex end_here ">
                  <div className=" ">
                    <p className="about_token ">
                      You pay ~
                      {isActive == 0 ? (
                        <>
                          $
                          {parseFloat(
                            Number(ethToUSDT) * Number(get_userValue)
                          ).toFixed(2)}
                        </>
                      ) : isActive == 1 ? (
                        <>{get_userValue || 0} USDT</>
                      ) : (
                        <>{get_userValue || 0} USDC</>
                      )}
                    </p>
                    <div className="d-flex mt-2">
                      <div id="input_container">
                        <input
                          type="text"
                          id="user_va_in"
                          value={get_userValue}
                          onChange={(e) => setget_userValue(e.target.value)}
                        />
                        {isActive == 0 ? (
                          <>
                            <img src={Eth} id="input_img" alt="" width="12%" />
                          </>
                        ) : isActive == 1 ? (
                          <>
                            <img src={usdt} id="input_img" width="12%" alt="" />
                          </>
                        ) : (
                          <>
                            <img src={usdc} id="input_img" width="12%" alt="" />
                          </>
                        )}
                      </div>
                      <button
                        className="max_btn"
                        onClick={() =>
                          setget_userValue(
                            Number(Token_Balance) - Number(0.0001)
                          )
                        }
                      >
                        MAX
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3 mx-0 justify-content-center d-block d-md-none">
                <div className="col-md-12">
                  <div className=" ">
                    <p className="about_token ">
                      You pay ~
                      {isActive == 0 ? (
                        <>
                          {" "}
                          ${" "}
                          {parseFloat(
                            Number(ethToUSDT) * Number(get_userValue)
                          ).toFixed(2)}{" "}
                        </>
                      ) : isActive == 1 ? (
                        <>{Token_Balance} USDT</>
                      ) : (
                        <>{Token_Balance} USDC</>
                      )}
                    </p>
                    <div className="imp_inpu mt-2 mt-md-0">
                      <div className="token_input">
                        {isActive == 0 ? (
                          <>
                            <img src={Eth} className="price_pics" alt="" />
                          </>
                        ) : isActive == 1 ? (
                          <>
                            <img src={usdt} className="price_pics" alt="" />
                          </>
                        ) : (
                          <>
                            <img src={usdc} className="price_pics" alt="" />
                          </>
                        )}
                        <input
                          type="number"
                          placeholder="0"
                          className="input_token"
                          value={get_userValue}
                          onChange={(e) => setget_userValue(e.target.value)}
                        />
                      </div>
                      <button
                        className="max_btn"
                        onClick={() =>
                          setget_userValue(
                            Number(Token_Balance) - Number(0.0001)
                          )
                        }
                      >
                        MAX
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="change_wallter">
                    <p className="mb-0">You get ${spendBonus} bonus</p>
                  </div>
                </div>
                <div className="col-md-6 d-flex endeds  cn_ssm  mt-3 mt-md-0">
                  <div className="text-white d-flex mt-3 mt-md-0 gap-2">
                    <div
                      className="amoutn_usd"
                      onClick={() => (
                        setget_userValue(
                          isActive !== 0 ? 100 : Number(100) / ethToUSDT
                        ),
                        setspendBonus(4)
                      )}
                    >
                      $100
                    </div>
                    <div
                      className="amoutn_usd"
                      onClick={() => (
                        setget_userValue(
                          isActive !== 0 ? 250 : Number(250) / ethToUSDT
                        ),
                        setspendBonus(6)
                      )}
                    >
                      $250
                    </div>
                    <div
                      className="amoutn_usd"
                      onClick={() => (
                        setget_userValue(
                          isActive !== 0 ? 500 : Number(500) / ethToUSDT
                        ),
                        setspendBonus(8)
                      )}
                    >
                      $500
                    </div>
                    <div
                      className="amoutn_usd"
                      onClick={() => (
                        setget_userValue(
                          isActive !== 0 ? 1000 : Number(1000) / ethToUSDT
                        ),
                        setspendBonus(10)
                      )}
                    >
                      $1000
                    </div>
                    <div
                      className="amoutn_usd"
                      onClick={() => (
                        setget_userValue(
                          isActive !== 0 ? 2500 : Number(2500) / ethToUSDT
                        ),
                        setspendBonus(12)
                      )}
                    >
                      $2500
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row  mx-0  justify-content-center">
          <div className="col-md-10 z_upper_full mt-5">
            <div className="token_info">
              <div className="d-flex mb_view align-items-center justify-content-between">
                <div className=" ">
                  <div className="d-flex gap-2 align-items-center">
                    <h1 className="chain_balance">Choose Vesting Bonus</h1>
                  </div>
                </div>

                <div className=" change_wallter d-flex gap-3 ">
                  <h6 className="vesting">
                    {" "}
                    <span style={{ color: "#ababab", fontWeight: "bold" }}>
                      +{months}
                    </span>{" "}
                    Months{" "}
                    <span style={{ color: "#ababab", fontWeight: "bold" }}>
                      {" "}
                      {bounsShow}%{" "}
                    </span>{" "}
                    bonus
                  </h6>
                </div>
              </div>
              <div className="row mt-5 justify-content-center">
                <div className="col-2   col-md-3 text-center ps-0">
                  <h4 className="bes_ves"> Bonus</h4>
                </div>
                <div className="col-md-9">
                  <div className=" tokenBar_main">
                    <div className="text-center text-white">+8%</div>
                    <div className=" text-center text-white">+12%</div>
                    <div className=" text-center text-white">+20%</div>
                    <div className=" text-center text-white">+25%</div>
                    <div className=" text-center text-white">+30%</div>
                    <div className=" text-center text-white">+40%</div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div class="wrapper">
                      <div class="content">
                        <div class="range">
                          <input
                            type="range"
                            id="range1"
                            min="0"
                            max="98"
                            step="14"
                            defaultValue={14}
                            value={sliderValue}
                            onChange={handleSliderChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-white">
                  33% unlock at the TGE with 3 month cliff and the rest linearly
                  unlocked over the period of 9 months
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row   mx-0 justify-content-center">
          <div className="col-md-10 z_upper_full mt-5">
            <div className="token_info">
              <div className="d-flex mb_view align-items-center justify-content-between">
                <div className=" ">
                  <div className="d-flex gap-2 align-items-center">
                    <h1 className="chain_balance">You receive</h1>

                    <img src={logo} className="price_pics" alt="" />
                    <h6 className="mb-0 text-white fw-bold">
                      {Number(
                        get_userValue && stagePrice_show != 0
                          ? isActive !== 0
                            ? parseFloat(
                                Number(get_userValue) / Number(stagePrice_show)
                              ).toFixed(0)
                            : parseFloat(
                                Number(
                                  Number(ethToUSDT) * Number(get_userValue)
                                ) / Number(purchasedETH)
                              ).toFixed(0)
                          : 0
                      ) +
                        Number(
                          get_userValue && stagePrice_show && bounsShow != 0
                            ? isActive !== 0
                              ? (parseFloat(
                                  Number(get_userValue) /
                                    Number(stagePrice_show)
                                ).toFixed(0) *
                                  Number(bounsShow)) /
                                100
                              : (parseFloat(
                                  Number(
                                    Number(ethToUSDT) * Number(get_userValue)
                                  ) / Number(purchasedETH)
                                ).toFixed(0) *
                                  Number(bounsShow)) /
                                100
                            : 0
                        ) +
                        Number(check_QuantityBonus)}
                    </h6>
                  </div>
                  <p className="text-white  fw-bold">
                    Purchased{" "}
                    {get_userValue && stagePrice_show != 0
                      ? isActive !== 0
                        ? parseFloat(
                            Number(get_userValue) / Number(stagePrice_show)
                          ).toFixed(0)
                        : parseFloat(
                            Number(Number(ethToUSDT) * Number(get_userValue)) /
                              Number(purchasedETH)
                          ).toFixed(0)
                      : 0}
                    +
                    {get_userValue && stagePrice_show && bounsShow != 0
                      ? isActive !== 0
                        ? (parseFloat(
                            Number(get_userValue) / Number(stagePrice_show)
                          ).toFixed(0) *
                            Number(bounsShow)) /
                          100
                        : (parseFloat(
                            Number(Number(ethToUSDT) * Number(get_userValue)) /
                              Number(purchasedETH)
                          ).toFixed(0) *
                            Number(bounsShow)) /
                          100
                      : 0}
                    +{check_QuantityBonus}
                  </p>
                </div>

                <div className=" change_wallter d-flex gap-3 ">
                  {/* <button>Promo code </button> */}
                  <button className="mt-2 mt-md-0" onClick={handleSubmit}>
                    {spinner ? "Loading..." : "Confirm"}
                  </button>
                </div>
              </div>
              <div className="mt-3">
                <p className="text-white">
                  By making a transaction, you consent that you have read and
                  agreed to our Terms of Use.
                </p>

                <div className="steps mt-2">
                  <div>Step 1. Check balance</div>
                  <div>
                    <FaCheck style={{ display: address ? "block" : "none" }} />
                  </div>
                </div>
                <div className="steps mt-3">
                  <div>Step 2. Approve transaction in wallet</div>
                  <div>
                    <FaCheck style={{ display: approveCheck ? "block" : "none" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className=" change_wallter mt-3 d-flex gap-3 ">
              {/* <button>Promo code </button> */}
              <button className="mt-2 mt-md-0" onClick={USDTapproveSubmit}>
                {spinner ? "Loading..." : "Fix USDT"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
