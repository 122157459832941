import React, { useEffect } from "react";
import "./Home_landing.css";
import Header from "../Header_meta/Header_meta";

import bit from "../Assets/bybit.png";
import kucoin from "../Assets/kucoin.png";
import dao from "../Assets/dao.png";
import shima from "../Assets/shima.png";
import bia from "../Assets/binance.png";
import chaingpt from "../Assets/chaingpt.png";
import redswiss from "../Assets/redswiss.png";
// import g12 from "../Assets/"

import { FaLongArrowAltRight } from "react-icons/fa";
import apk from "../Assets/play.jpeg";
import bnb from "../Assets/bnb.png";
import gold from "../Assets/gold.png";
import sliver from "../Assets/silver.png";

import { useRef, useState } from "react";
import "swiper/css";
import aduit_by from "../Assets/audit.svg"
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import app from "../Assets/app.jpeg";
import gate from "../Assets/gate.png";
import bitget from "../Assets/bitget.png";

import pancake from "../Assets/pancake.png";
import mex from "../Assets/mex.png";
import htx from "../Assets/htx.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import Web3 from "web3";
import { PresaleEthV2_ABI, PresaleEthV2_Address } from "../../utilies/constant";
import bgVideo from "../Assets/video.mp4";

export default function Home_landing() {
  const webSupply = new Web3("https://bsc-rpc.publicnode.com");
  const [raisedValue, setraisedValue] = useState(0);
  const [totalalltimeusd, settotalalltimeusd] = useState(0);

  const [currentStagePrice, setcurrentStagePrice] = useState(0);
  const [nextstagePrice, setnextstagePrice] = useState(0);
  const [currentphasenumber, setcurrentphase] = useState(1);




  const getValue = async () => {
    try {
      let contractOf = new webSupply.eth.Contract(
        PresaleEthV2_ABI,
        PresaleEthV2_Address
      );
      let currentStagePriceusdt = await contractOf.methods
      .stagePrice()
      .call();
      currentStagePriceusdt=webSupply.utils.fromWei(currentStagePriceusdt.toString());
      setcurrentStagePrice(currentStagePriceusdt)

      let phase = await contractOf.methods
      .currentPhase()
      .call();

      setcurrentphase(Number(phase)+Number(1))      
      let nextprice = await contractOf.methods
      .phases(Number(phase)+Number(1))
      .call();
      nextprice=nextprice[1]
      nextprice = webSupply.utils.fromWei(nextprice.toString());

      setnextstagePrice(nextprice)
      let totalTokensSoldInStage = await contractOf.methods
        .totalTokensSold()
        .call();
      let stagePrice = await contractOf.methods.stagePrice().call();
      stagePrice = webSupply.utils.fromWei(stagePrice.toString());
      console.log("totalTokensSoldInStage", stagePrice);
      let raisedPrice = Number(totalTokensSoldInStage) * Number(stagePrice);
      raisedPrice=parseFloat(raisedPrice).toFixed(0)
      raisedPrice=Number(raisedPrice)
      // setraisedValue(raisedPrice.toLocaleString('en-US'));
      let linearpriceusdRaised = await contractOf.methods
      .linearPriceUsdRaised()
      .call();
      linearpriceusdRaised = webSupply.utils.fromWei(linearpriceusdRaised.toString());


      linearpriceusdRaised=Number(10000)+Number(linearpriceusdRaised)

      setraisedValue(Number(linearpriceusdRaised));



      let currentphasetottalsold = await contractOf.methods
      .phases(Number(phase))
      .call();
      currentphasetottalsold=currentphasetottalsold[2]
      currentphasetottalsold=webSupply.utils.fromWei(currentphasetottalsold.toString());
      let nextphasetottalsold = await contractOf.methods
      .phases(Number(phase)+Number(1))
      .call();
      nextphasetottalsold=nextphasetottalsold[2]
      nextphasetottalsold=webSupply.utils.fromWei(nextphasetottalsold.toString());
      settotalalltimeusd(Number(currentphasetottalsold))

    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getValue();
  }, []);
  const Completionist = () => {
    return (
      <>
        <div
          className="text_days fs-5 "
          style={{ marginTop: "-0.2rem", marginLeft: "1rem" }}
        >
          <span className="me-2">Time End!</span>
        </div>
      </>
    );
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <div className="timer_pre mt-4">
          <div className="d-flex flex-column justify-content-center text-center">
            <h4 className="text-white mb-0">{days}</h4>
            <p className="text-white">Days</p>
          </div>
          <div className="d-flex flex-column justify-content-center text-center">
            <h4 className="text-white mb-0">{hours}</h4>
            <p className="text-white">Hours</p>
          </div>
          <div className="d-flex flex-column justify-content-center text-center">
            <h4 className="text-white mb-0">{minutes}</h4>
            <p className="text-white">Minutes</p>
          </div>
          <div className="d-flex flex-column justify-content-center text-center">
            <h4 className="text-white mb-0">{seconds}</h4>
            <p className="text-white">Seconds</p>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="main_landing_page">
      <Header flag="true" />

      {/* <div>
        <video src={bgVideo}></video>
      </div> */}

      <div className="overlay1"></div>

      <div className="container ">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-6 col-md-12 z_upper_full">
            <div className="home_content">
              <h1 className="site_font" style={{ color: "#fff" }}>
                Blockchain AI Execution Protocol{" "}
              </h1>
              <h2 className="mt-3 landinngs_paraa site_font">
                A Web3 AI execution technology that provides you with access to
                CeFi,Defi,and NFT crypto makers through an all-in-one
                conversational AI interfaces.
              </h2>

              <h6 className="mvp site_font mt-5">Infinite Wallet available </h6>

              <div className="d-flex gap-3 mt-4">
                <img src={apk} className="donw_sm" alt="" />
                <img src={app} className="donw_sm" alt="" />
              </div>
              {/* <h6 className="mvp mt-4">Developed by </h6>
              <h6 className="team_better">BetterLogics Team</h6> */}
              <h2 className="mt-2 site_font lsfnks">
                Most Powerful Trading Ever, Infinite App isn’t just another
                trading App: it’s a game-changer.It’s not just powerful -it’s
                the epitome of automated trading.
              </h2>

              <div className="d-flex gap-2 align-items-center">
                <Link to="/staking">
                  {" "}
                  <button
                    className="get_start_btn  mt-2"
                    // disabled
                    // style={{ cursor: "no-drop" }}
                  >
                    {" "}
                    Staking
                  </button>
                </Link>
                <Link to="/proflie_page">
                  <div
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    <button className="get_start_btn mt-2">
                      {" "}
                      go to proflie
                    </button>
                  </div>
                </Link>
              <img   src={aduit_by} className=" auditby_imgg  mb-0" alt="" />
              </div>

            </div>
          </div>

          <div className="col-lg-5 col-md-10 z_upper_full px-0 px-md-2 mt-3 mt-md-5 mt-3 mt-md-0">
            <div className="prsale_box">
              <h6 className="site_font">Presale Stage {currentphasenumber}</h6>
              <p className="bonus site_font my-3">
                UP TO <span style={{ color: "#547CFF" }}> +117.00%</span> BONUS
              </p>
              <div className="row mt-2 mt-md-0">
                <div className="col-4">
                  <div className="bonus_box text-center">
                    <h6 className="site_font">Stage Bonus</h6>
                    <p className="mb-0 bonus_persn site_font">+50.00%</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="bonus_box text-center">
                    <h6 className="site_font">Buying Bonus</h6>
                    <p className="mb-0 bonus_persn site_font">+25.00%</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="bonus_box text-center">
                    <h6 className="site_font">Vesting Bonus</h6>
                    <p className="mb-0 bonus_persn site_font">+25.00%</p>
                  </div>
                </div>
              </div>

              <h5 className="text-center inf_token_pric site_font fw-bold mt-3">
                $INFAI TOKEN PRICE
              </h5>
              <div className="row justify-content-between my-3">
                <div className="col-5">
                  <p className="token_price">
                   {currentStagePrice} <span style={{ color: "#547CFF" }}> USDT </span>
                  </p>
                </div>

                <div className="col-5 text-end   ">
                  <p className="token_price">

                  <span style={{color:'red'}}>{nextstagePrice}</span>   <span style={{ color: "#547CFF" }}> USDT </span>
                  </p>
                </div>
              </div>
              <div className="row justify-content-center my-3">
                <div className="col-4">
                  <h5 className="site_font cureent_stage">Current</h5>
                </div>
                <div className="col-4">
                  {/* <h5 className="site_font cureent_percent">+18.51%</h5> */}
                </div>
                <div className="col-4 text-end">
                  <h5
                    className="site_font cureent_percent"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Next Stage
                  </h5>
                </div>
              </div>

              <div className="relative mt-6">
                <div className="h-6 border border-accent rounded-sm" />
                <div
                  className="h-6 bg-accent rounded-sm  absolute top-0 left-0"
                  style={{
                    width: ((raisedValue) / (totalalltimeusd)) * (100)
                  }}
                />
                <div className="uppercase sold_out_content">
                  Total Raised{" "}
                  {raisedValue}
                </div>
              </div>

              <h4 className="text-center total_raised site_font mt-3">
                TOTAL RAISED:
              </h4>
              <p className="fw-bold text-center" style={{ color: "#547CFF",fontSize:"20px" }}>
                ${raisedValue.toLocaleString('en-US')} /${totalalltimeusd.toLocaleString('en-US')}
              </p>
              <div className="d-flex justify-content-center mt-4">
                <Link to="/buy_token" className="text-deoration-none" onClick={()=>window.scrollTo(0, 0)}>
                  <button className="buy_token mt-2 mt-md-0"> buy token</button>
                </Link>
              </div>

              <p className="text-center text-white site_font mt-4 fw-bold">
                Listing Price{" "}
                <span style={{ color: "#547CFF" }}> 0.025 (+714.15%)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
