import React, { useEffect, useState } from "react";
import "./Refferal_page.css";
import logo from "../Assets/site_logo.png";
import screen from "../Assets/refferal.png";
import { IoBarChartOutline } from "react-icons/io5";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWeb3Modal } from "@web3modal/wagmi/react";

export default function Refferal_page() {
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const [refAddress, setRefAddress] = useState("");
  const [copied, setCopied] = useState(false);
  let history = window.location;

  useEffect(() => {
    if (address) {
      setRefAddress(`${history.origin}/buy_token?ref=${address}`);
    } else {
      setRefAddress("Connect wallet");
    }
    setInterval(() => {
      setCopied(false);
    }, 3000);
  }, [address, copied]);

  return (
    <div className="main_refferal_page ">
    <div className="overlay1"></div>
     
      <div className="container">
      <div className="row">
        <div className="col-md-12 z_upper_full">

        <h1 className="ref_head site_font  pt-5">
          Earn Money by Selling $INFAI to Your Audience!
        </h1>
        </div>
      </div>
        <div className="z_upper_full mt-5">
          <div className="text-center position-relative z_upper_full site_font">
            {address ? (
              <>
                <div>
                  <input
                    type="text"
                    className="refferal_input"
                    value={refAddress}
                    style={{ border: "none", outline: "none" }}
                    placeholder="referral link"
                  />

                  <CopyToClipboard
                    text={refAddress}
                    onCopy={() => setCopied(true)}
                  >
                    <button className="btn_ref">
                      {copied ? "COPIED" : "COPY"}{" "}
                    </button>
                  </CopyToClipboard>
                </div>
              </>
            ) : (
              <>
                <button
                  className="buy_token"
                  onClick={() =>
                    address
                      ? chain?.id == chains[0]?.id
                        ? open()
                        : switchNetwork?.(chains[0]?.id)
                      : open()
                  }
                >
                    {address ? (
                      chain?.id == chains[0]?.id || chain?.id == chains[1]?.id ? (
                        address ? (
                          <>
                            {`${address.substring(0, 6)}...${address.substring(
                              address.length - 4
                            )}`}
                          </>
                        ) : (
                          <>Connect your wallet to get a refferal link</>
                        )
                      ) : (
                        "Switch NewWork"
                      )
                    ) : (
                      <>Connect your wallet to get a refferal link</>
                    )}
                </button>
              </>
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4 z_upper_full">
            <div className="referal_">
              <h6 style={{lineHeight:'30px'}}>
                <span style={{ color: "#4f78ff", fontWeight: "700" }}>
                  START NOW
                </span>{" "}
                and get a unique referral link by connecting your wallet
              </h6>
            </div>
          </div>
          <div className="col-md-4 z_upper_full mt-3 mt-md-0">
            <div className="referal_">
              <h6 style={{lineHeight:'30px'}}>
                Using your{" "}
                <span style={{ color: "#4f78ff", fontWeight: "700" }}>
                  {" "}
                  marketing strategies
                </span>
                , promote your referral link
              </h6>
            </div>
          </div>
          <div className="col-md-4 z_upper_full mt-3 mt-md-0">
            <div className="referal_">
              <h6 style={{lineHeight:'30px'}}>
                You will get USDT for each referral who uses your link to
                purchase
                <span style={{ color: "#4f78ff", fontWeight: "700" }}>
                  {" "}
                  $INFAI.
                </span>{" "}
              </h6>
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-center mt-5">
          <div className="col-md-6 z_upper_full d-flex justify-content-center">
            <img src={logo} className="w-50" alt="" />
          </div>
          <div className="col-md-6 z_upper_full mt-3 mt-md-0">
            <div className="feartures">
              <h3>Key features of our referral program</h3>
              <ul className="feartue_list">
                <li>More friends = more money</li>
                <li>The more they purchase, the more you earn.</li>
                <li>
                  Get a lifetime commission of up to 15% on all holders
                  attracted.
                </li>
                <li>
                  Review detailed stats in your profile to adjust and earn more.
                </li>
                <li>Withdrawals available starting from 50 BUSD</li>
              </ul>
              {/* <div>
                <button className="buy_token">
                  connect your wallet to get refferal link
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="profile_screen_shot mt-3">
          <h1>Profile Screenshot</h1>
          <div className="col-md-12 z_upper_full mt-3 position-relative d-flex justify-content-center">
            <img src={screen} className="w-100" alt="" />
          </div>
        </div>
        <div className="row reffeea_reward_Sems align-items-center">
          <div className="col-md-6 z_upper_full mt-3 mt-md-0">
            <div className="about_ref">
              {/* <h4 className="ref_pro">Referral Program</h4> */}
              {/* <p>Earn Up to a 15% Bonus with You Referral Link</p> */}
              <div>
                <IoBarChartOutline className="bars " />
              </div>
            </div>
          </div>
          <div className="col-md-6 z_upper_full mt-3 mt-md-0">
            <div className="about_ref">
              <h4 className="ref_pro text-white my-2 z_upper_full position-relative" style={{ fontWeight: "700" }}>
                Referral Rewards
              </h4>
              <p className="text-white z_upper_full position-relative my-2">
                Share your referral link with your friends and business partners
                and get a bonus from every successful purchase.
              </p>
              <div>
                <div className="row">
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>Amount</p>
                    </div>
                  </div>
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>Bonus, %</p>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>≤ $1 000</p>
                    </div>
                  </div>
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>5</p>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>$1 001 - $5 000</p>
                    </div>
                  </div>
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>7</p>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>$1 001 - $5 000</p>
                    </div>
                  </div>
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>10</p>
                    </div>
                  </div>
                </div>
                <div className="row  ">
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>$10 001 - $25 000</p>
                    </div>
                  </div>
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>12</p>
                    </div>
                  </div>
                </div>
                <div className="row  ">
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>$25 001 - $50 000</p>
                    </div>
                  </div>
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>13</p>
                    </div>
                  </div>
                </div>
                <div className="row  ">
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>$50 001 - $100 000</p>
                    </div>
                  </div>
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>14</p>
                    </div>
                  </div>
                </div>
                <div className="row  ">
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>≥ $100 001</p>
                    </div>
                  </div>
                  <div className="col-6 mt-2 mt-md-1 mt-md-0">
                    <div className="reff_bows">
                      <p>15</p>
                    </div>
                  </div>
                </div>

                {/* <IoBarChartOutline className='bars ' /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center position-relative z_upper_full site_font">
            {address ? (
              <>
                <div>
                  <input
                    type="text"
                    className="refferal_input"
                    value={refAddress}
                    style={{ border: "none", outline: "none" }}
                    placeholder="referral link"
                  />

                  <CopyToClipboard
                    text={refAddress}
                    onCopy={() => setCopied(true)}
                  >
                    <button className="btn_ref">
                      {copied ? "COPIED" : "COPY"}{" "}
                    </button>
                  </CopyToClipboard>
                </div>
              </>
            ) : (
              <>
                <button
                  className="buy_token"
                  onClick={() =>
                    address
                      ? chain?.id == chains[0]?.id
                        ? open()
                        : switchNetwork?.(chains[0]?.id)
                      : open()
                  }
                >
                  {address ? (
                    chain?.id == chains[0]?.id || chain?.id == chains[1]?.id ? (
                      address ? (
                        <>
                          {`${address.substring(0, 6)}...${address.substring(
                            address.length - 4
                          )}`}
                        </>
                      ) : (
                        <>Connect your wallet to get a refferal link</>
                      )
                    ) : (
                      "Switch NewWork"
                    )
                  ) : (
                    <>Connect your wallet to get a refferal link</>
                  )}
                </button>
              </>
            )}
          </div>
        <div className="row justify-content-center mt-5">
          <h1 className="str_m z_upper_full">How to Start Making Money Right Away?</h1>
          <div className="col-md-2 z_upper_full sm_alin ">
            <div className="make_mo">
              <h1 className="fw-bold">1</h1>
              <p>
                Click the profile icon or go to profile to access your account.
              </p>
            </div>
          </div>
          <div className="col-md-2 z_upper_full sm_alin">
            <div className="make_mo">
              <h1 className="fw-bold">2</h1>

              <p>Connect your wallet.</p>
            </div>
          </div>
          <div className="col-md-2 z_upper_full sm_alin">
            <div className="make_mo">
              <h1 className="fw-bold">3</h1>

              <p>Use the green button to copy your referral link.</p>
            </div>
          </div>
          <div className="col-md-2 z_upper_full sm_alin">
            <div className="make_mo">
              <h1 className="fw-bold">4</h1>

              <p>
                Share the our referral link on your social media and other
                platforms.
              </p>
            </div>
          </div>
          <div className="col-md-2 z_upper_full sm_alin">
            <div className="make_mo">
              <h1 className="fw-bold">5</h1>

              <p>
                You will get a commission on any transactions made by the users
                you bring in.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
