import React from 'react'
import "./Technology.css"
import tech from "../Assets/Technology Graph.png"

export default function Technology() {
  return (
    <div className='main_tech_nolo' id="Technology">
    <div className="container">
        <h1 className='site_font protection_main'>Technology</h1>
        <div className="row">
            <div className="col-md-12">
                <img src={tech} className='w-100 ' alt="" />
            </div>
        </div>
    </div>
    </div>
  )
}
