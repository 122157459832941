import React from 'react'
import "./Protection.css"
import feature from "../Assets/feature_bg.png"
import protection from "../Assets/protection_bg.png"

export default function Protection() {
  return (
    <div className='protection_main'>
        <img src={feature} className='feature_bg d-none d-lg-block' alt="" />
        <div className="container">
            <h1 className='protection_main site_font'>Infinite AI Protection Open Pool</h1>
            <div className="row align-items-center">
                <div className="col-md-6 z_upper_full">
                    <img src={protection} alt="" />
                </div>

                <div className="col-md-6 z_upper_full">

                    <p className="protection_text site_font">Stop losing funds during your trading, Infinite AI protection designed to protect the trader from losing money while they are trading with the assistance of Infinite AI.</p>
                    <p className="protection_text site_font">Never lose funds, The AI Infinite open pool pays back any lose.</p>
                </div>
            </div>
        </div>


    </div>
  )
}
