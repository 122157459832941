import React from "react";
import "./Tokenomics.css";
import token from "../Assets/Tokenomics Graph.png"


export default function Tokenomics() {
  return (
    <div className="main_token_nomics" id="Tokenomics">
      <div className="container">
        <h1 className="tokenomic_head site_font text-center">Tokenomics</h1>
        <div className="row align-items-center justify-content-center mt-5">
        <div className="col-md-10">
          <img src={token} className="w-100" alt="" />
        </div>
         
        </div>
      </div>
    </div>
  );
}
