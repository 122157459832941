import React from "react";
import "./RoadMap_links.css";

export default function RoadMap_links() {
  return (
    <div className="main_raod_links" id="RoadMap">
      <div className="overlay11"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 z_upper_full">
            <h1 className="links_head text-white">Infinite Protocol Roadmap</h1>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6 z_upper_full mt-md-5">
            <div className="road_box">
              <h6 className="question_head site_font">2023</h6>
              <p className="question_para site_font ">
                Achieving an organic growth of 50k user base Continued product
                development, scaling and growth Developing of AI Infinite
                indicator platform Establishing partnership with Binance and
                Tron
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center d-none d-lg-flex mt-3 mt-md-5">
          <div className="col-lg-3 col-md-6 z_upper_full mt-3 mt-md-5">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 2 </h3>
              <ul className="ps-0 links_here">
                <li>Become (Binance, Coinbase, Bybit, kucoin) broker </li>
                <li>Release of Infinite AI Investment (Closed Alpha)</li>
                <li>   Release of web3 funds v2.0 </li>
                <li>   Release of Infinite indicator v.2.0 </li>
                <li>  Release of Infinite AI trading signals </li>
                <li>  Infinite AI wallet release v2.0</li>

              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 z_upper_full mt-3 mt-md-5">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 4</h3>
              <ul className="ps-0 links_here">
                <li>Infinite API Analysis v2.0 release </li>
                <li>Infinite AI integration with partners retailers (Apple, eBay, Amzon, etc). </li>
                <li>Infinite AI integration with Dex </li>
                <li>Infinite AI wallet release v2.0</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 z_upper_full mt-3 mt-md-5">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 6 </h3>
              <ul className="ps-0 links_here">
                <li>infinite AI Executions tech v3.0 release </li>
                <li>Infinite AI Protection pool v3.0 release </li>
                <li>Infinite API SDK v3.0 release </li>
                <li>Infinite AI trading signals v3.0 release</li>

              </ul>
            </div>
          </div>
        </div>

        <div className="row mt-5  d-none d-lg-flex mt-md-5">
          <div className="line position-relative z_upper_full d-none d-md-block">
            <div className="cirle"></div>
            <div className="questions">
            <h6 className="year_here ">2024</h6>
              <p className="question_number">Q1/24</p>
            </div>
            <div className="cirlee"></div>
            <div className="questionss">
              <p className="text-white question_number font_site">Q1/24</p>
            </div>
            <div className="cirlee3"></div>
            <div className="questionss3">
              <p className="text-white question_number font_site">Q2/24</p>
            </div>
            <div className="cirlee4"></div>
            <div className="questionss4">
              <p className="text-white question_number font_site">Q3/24</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 z_upper_full mt-3">
            <div className="links_content first_phase">
              <h3 className="ph_1">Phase 1</h3>
              <ul className="ps-0 links_here">
                <li>INFAI Token Issuance  </li>
                <li>Website Launch </li>
                <li>Auditing & KYC </li>
                <li>Marketing & awareness </li>
                <li>ICO & Presale </li>
                <li>TGE </li>
                <li>Cex Listing (Mexc , Bitget, Bybit , Kucoin, etc)</li>

              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 z_upper_full mt-3">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 3</h3>
              <ul className="ps-0 links_here">
                <li>Infinite API SDK v1.0 release </li>
                <li>Infinite AI portfolio management release </li>
                <li>Integration with cexs </li>
                <li>Infinite AI Protection pool v1.0 release • </li>
                <li>Infinite AI Algorithmic trading platform v1.0 </li>
                <li>Infinite AI shopping assistance with cashback in crypto</li>

              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 z_upper_full mt-3">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 5</h3>
              <ul className="ps-0 links_here">
                <li>
                Infinite AI Executions tech v2.0 release
                </li>
                <li>Infinite AI Protection pool v2.0 release </li>
                <li>Infinite API SDK v2.0 release </li>
                <li>Infinite AI NFT management v1.0 release </li>
                <li>Infinite AI NFT marketplace v1.0 release </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 z_upper_full mt-3">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 7</h3>
              <ul className="ps-0 links_here">
                <li> Infinite AI web3 v3.0 release  </li>
                <li>Infinite AI wallet release v.3.0</li>

              </ul>
            </div>
          </div>
        </div>



        {/* mobile roadmap here */}

        <div className="row d-flex d-lg-none mt-md-5">
        <div className="col-md-3 col-md-6 z_upper_full mt-3">
            <div className="links_content first_phase">
              <h3 className="ph_1">Phase 1</h3>
              <ul className="ps-0 links_here">
                <li>INFAI Token Issuance  </li>
                <li>Website Launch </li>
                <li>Auditing & KYC </li>
                <li>Marketing & awareness </li>
                <li>ICO & Presale </li>
                <li>TGE </li>
                <li>Cex Listing (Mexc , Bitget, Bybit , Kucoin, etc)</li>

              </ul>
            </div>
          </div>
          <div className="col-md-3 col-md-6 z_upper_full mt-3">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 2 </h3>
              <ul className="ps-0 links_here">
                <li>Become (Binance, Coinbase, Bybit, kucoin) broker </li>
                <li>Release of Infinite AI Investment (Closed Alpha)</li>
                <li>   Release of web3 funds v2.0 </li>
                <li>   Release of Infinite indicator v.2.0 </li>
                <li>  Release of Infinite AI trading signals </li>
                <li>  Infinite AI wallet release v2.0</li>

              </ul>
            </div>
          </div>
          <div className="col-md-3 col-md-6 z_upper_full mt-3">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 3</h3>
              <ul className="ps-0 links_here">
                <li>Infinite API SDK v1.0 release </li>
                <li>Infinite AI portfolio management release </li>
                <li>Integration with cexs </li>
                <li>Infinite AI Protection pool v1.0 release • </li>
                <li>Infinite AI Algorithmic trading platform v1.0 </li>
                <li>Infinite AI shopping assistance with cashback in crypto</li>

              </ul>
            </div>
          </div>
          <div className="col-md-3 col-md-6 z_upper_full mt-3">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 4</h3>
              <ul className="ps-0 links_here">
                <li>Infinite API Analysis v2.0 release </li>
                <li>Infinite AI integration with partners retailers (Apple, eBay, Amzon, etc). </li>
                <li>Infinite AI integration with Dex </li>
                <li>Infinite AI wallet release v2.0</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-md-6 z_upper_full mt-3">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 5</h3>
              <ul className="ps-0 links_here">
                <li>
                Infinite AI Executions tech v2.0 release
                </li>
                <li>Infinite AI Protection pool v2.0 release </li>
                <li>Infinite API SDK v2.0 release </li>
                <li>Infinite AI NFT management v1.0 release </li>
                <li>Infinite AI NFT marketplace v1.0 release </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-md-6 z_upper_full mt-3">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 6 </h3>
              <ul className="ps-0 links_here">
                <li>infinite AI Executions tech v3.0 release </li>
                <li>Infinite AI Protection pool v3.0 release </li>
                <li>Infinite API SDK v3.0 release </li>
                <li>Infinite AI trading signals v3.0 release</li>

              </ul>
            </div>
          </div>
          <div className="col-md-2 col-md-6 z_upper_full mt-3">
            <div className="links_content first_phase">
              <h3 className="ph_2">Phase 7</h3>
              <ul className="ps-0 links_here">
                <li> Infinite AI web3 v3.0 release  </li>
                <li>Infinite AI wallet release v.3.0</li>

              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
