import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import  logo from "../../Components/Assets/new_logo.png"
import google_logo from "../../Components/Assets/google_logo.svg"
import binanace from "../../Components/Assets/bnb.png"

export default function SingUp() {
  const [getData, setgetData] = useState({ email: "", password: "" });
  const [spinner, setSpinner] = useState(false);

  const handleclick = (event) => {
    const { name, value } = event.target;
    setgetData({ ...getData, [name]: value });
  };

  let navigate = useNavigate();
  const email = "metalinkpresale@gmail.com";
  const password = "123456";

  const logIn = async () => {
    try {
      if (getData.email == "" || getData.password == "") {
        return toast.error("Please fill all fields first");
      }
      setSpinner(true);
      let res = await axios.post("https://server.infiniteprotocol.io/admin_register", {
        email: getData.email,
        password: getData.password,
        userName:getData.userName
      });
      if (res.data.success == true) {
        navigate("/LogIn");
        console.log("getData", res.data);
        toast.success("Sign Up Successfull !");
        setSpinner(false);
      } else {
        toast.error(res.data.msg);
        setSpinner(false);
      }
    } catch (error) {
      console.log(error);
      setSpinner(false);
    }
  };
  return (
    <div className="main_login_section">
    <div className="overlay1"></div>
      <section>
        <div className="row justify-content-center ">

        <div className="col-md-6 mt-3 mt-md-0 z_upper_full  pe-0 d-flex flex-column justify-content-center align-items-center">
          <img src={logo} className="w-50" alt="" />
        </div>
        <div className="col-md-6 d-flex justify-content-center z_upper_full">
        <div className="w-full  rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold text-capitalize leading-tight tracking-tight text-white site_font">
                Sign Up for an account
              </h1>
              <div className="space-y-4 md:space-y-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-white site_font"
                  >
                    Your Name
                  </label>
                  <input
                    type="text"
                    name="userName"
                    onChange={handleclick}
                    id="userName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter Your Name"
                    required=""
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-white site_font"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleclick}
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium site_font text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    onChange={handleclick}
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                  />

                  <div>
                  <div class="form-check mt-2">
  <input class="form-check-input" type="checkbox" value="" id="term_nd_condition"/>
  <label class="form-check-label term_nd_condtions " for="term_nd_condition">
   By Creating an account  means you agree to the <span style={{color:"#4f78ff"}} >terms & conditions</span>   and our <span style={{color:"#4f78ff"}} > privacy policy </span>
  </label>
</div>
                  </div>
                </div>

                <button
                  onClick={logIn}
                  style={{ backgroundColor: "#5177FC" }}
                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5
                  py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                    {
                        spinner ? "Loading...":"Sign Up"
                    }

                </button>


                <h6 className="or_sign_up">or</h6>


                <button className="sign_up_with_google">
                  <img src={google_logo} className="butn_logo_width" alt="" />
                  Continue with Google
                </button>
                <button className="sign_up_with_google">
                  <img src={binanace} className="butn_logo_width" alt="" />
                  Continue with Binance
                </button>
                <p className="text-sm font-light text-center text-white site_font">
                  Already have an account ?{" "}
                  <Link
                    to="/LogIn"
                    style={{ color: "#5177FC" }}
                    className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        </div>
      </section>
    </div>
  );
}
