import React from "react";
import "./Footer_meta.css";
import footer_img from "../Assets/footer_logo.png";
import tele from "../Assets/telegram.png";
import twi from "../Assets/twitter_logo.png";
import linkdin from "../Assets/linkdin.png";
import youtube from "../Assets/youtube.png";
import medium from "../Assets/medium.png";
import coinmarket from "../Assets/coinmarket.png";
import { NavHashLink } from "react-router-hash-link";

export default function Footer_meta() {
  return (
    <div className="main_footer_bg">
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="col-md-3 col-6">
            <div className="d-flex align-items-center gap-2">
              <img src={footer_img} alt="" />{" "}
              <span className="infi_logg"> Infinite Protocol</span>
            </div>

            <p className="site_about">
              A Web3 AI execution technology that provides you with access to
              CeFi,Defi,and NFT crypto makers through an all-in-one
              conversational AI interfaces.
            </p>
          </div>
          <div className="col-md-3 col-6">
            <div className="lower_links">
              <h6>Social</h6>
              <div className="liks_footees">
                <div className="d-flex align-items-center mt-4 gap-2">
                <a href="http://t.me/infiniteprotocol" target="_blank" className="footer_linkss">  <img src={tele} className="footer_links_img" alt="" />{" "}</a>
                <a href="http://t.me/infiniteprotocol" target="_blank" className="footer_linkss"><span className="footer_linkss"> News Chanel</span></a>
                </div>
                <div className="d-flex align-items-center my-2 gap-2">
                <a href="http://t.me/infiniteprotocol" target="_blank" className="footer_linkss"> <img src={tele} className="footer_links_img" alt="" />{" "}</a> 
                <a href="http://t.me/infiniteprotocol" target="_blank" className="footer_linkss"> <span className="footer_linkss"> Global Official Chat</span></a> 
                </div>
                <div className="d-flex align-items-center my-2 gap-2">
                <a href="http://t.me/infiniteprotocol" target="_blank" className="footer_linkss"> <img src={tele} className="footer_links_img" alt="" />{" "}</a> 
                <a href="http://t.me/infiniteprotocol" target="_blank" className="footer_linkss"> <span className="footer_linkss"> IPAI Traders Chat</span></a> 
                </div>
                <div className="d-flex align-items-center my-2 gap-2">
                <a href="https://twitter.com/infinitprotocol" target="_blank" className="footer_linkss">  <img src={twi} className="footer_links_img" alt="" />{" "}</a>
                <a href="https://twitter.com/infinitprotocol" target="_blank" className="footer_linkss"> <span className="footer_linkss"> Twitter</span></a>
                </div>
                <div className="d-flex align-items-center my-2 gap-2">
                  <img src={linkdin} className="footer_links_img" alt="" />{" "}
                  <span className="footer_linkss"> LinkedIn</span>
                </div>
                <div className="d-flex align-items-center my-2 gap-2">
                  <img src={medium} className="footer_links_img" alt="" />{" "}
                  <span className="footer_linkss"> Medium</span>
                </div>
                <div className="d-flex align-items-center my-2 gap-2">
                  <img src={youtube} className="footer_links_img" alt="" />{" "}
                  <span className="footer_linkss"> YouTube</span>
                </div>
                <div className="d-flex align-items-center my-2 gap-2">
                  <img src={coinmarket} className="footer_links_img" alt="" />{" "}
                  <span className="footer_linkss"> CoinMarketCap</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="lower_links">
              <h6>Navigation</h6>
              <a href="">Use cases</a>
              <NavHashLink smooth to="/#Technology">
                Technology
              </NavHashLink>
              <a href="">Track Record</a>

              <NavHashLink smooth to="/#Partners">
                Partners
              </NavHashLink>
              <NavHashLink smooth to="/#Team">
                Team
              </NavHashLink>

              <a href="">Jobs</a>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="lower_links">
              <h6>Contact</h6>
              <a href="">Contact us</a>
              <a href="mailto:hello@InfiniteProtocol.io">hello@InfiniteProtocol.io</a>
              <a href="">Brand kit</a>
            </div>
          </div>
        </div>
        <div className="row lower_links_footer justify-content-center">
          <div className="col-md-6 col-sm-12 text-center">
            <div className="d-flex gap-3 justify-content-md-start justify-content-center text-center text-white">
              <p>Privacy Policy</p>
              <p>Terms of service</p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 text-white text-lg-end text-center">
            <p>Copyright@2024 Infinite Protocol.All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
