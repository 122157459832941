import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import { FaGoogle, FaTelegramPlane } from "react-icons/fa";

import logo from "../../Components/Assets/new_logo.png";
import { FaFacebookF } from "react-icons/fa6";
import { TbBrandBinance } from "react-icons/tb";

export default function Auth() {
  const [getData, setgetData] = useState({ email: "", password: "" });
  const [spinner, setSpinner] = useState(false);
  const handleclick = (event) => {
    const { name, value } = event.target;
    setgetData({ ...getData, [name]: value });
  };
  let navigate = useNavigate();
  const logIn = async () => {
    try {
      // console.log("getData",getData);
      if (getData.email == "" || getData.password == "") {
        return toast.error("Please fill all fields first");
      }
      setSpinner(true);
      let res = await axios.post(
        "https://server.infiniteprotocol.io/admin_login",
        {
          email: getData.email,
          password: getData.password,
        }
      );
      if (res.data.success == true) {
        navigate("/dashboard");
        console.log("getData", res?.data?.data?.userName);
        toast.success("LogIn Successfull!");
        sessionStorage.setItem("Auth", true);
        sessionStorage.setItem("userData", res?.data?.data?.userName);

        setSpinner(false);
      } else {
        toast.error(res.data.msg);
        setSpinner(false);
      }
    } catch (error) {
      console.log(error);
      setSpinner(false);
    }
  };
  return (
    <div className="main_login_section">
      <div className="overlay1"></div>
      <section>
        <div className="row justify-content-center ">
          <div className="col-md-6 z_upper_full  pe-0 d-flex flex-column justify-content-center align-items-center">
            <img src={logo} className="w-50 d-block" alt="" />
            {/* <p className="sing_logotext">infinite protocol</p> */}
          </div>
          <div className="col-md-6 mt-3 mt-md-0 z_upper_full ps-0 diferefernt_clr d-flex justify-content-center z_upper_full">
            <div className="w-full  Z_upper_full rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
              <div className="p-6 z_upper_full space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold sign_up_text site_font">
                  Sign in to your account
                </h1>
                <div className="space-y-4 md:space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm site_font text-white"
                    >
                      Your email
                    </label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleclick}
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="name@company.com"
                      required=""
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium site_font text-white"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      onChange={handleclick}
                      id="password"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                    />
                  </div>
                  <div className="d-flex align-items-center justify-between">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Remember me
                      </label>
                    </div>

                    <div>
                      <a href="" className="forgot_passwoed ">
                        {" "}
                        Forgot password?
                      </a>
                    </div>
                  </div>

                  <button
                    onClick={logIn}
                    style={{ backgroundColor: "#5177FC" }}
                    className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5
                  py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    {spinner ? "Loading..." : "Sign in"}
                  </button>

                  <div className="d-flex gap-2 justify-center ">
                    <div className="sigin_logos">
                      <FaGoogle style={{fontSize:"1.5rem"}} />
                    </div>
                    <div className="sigin_logos">
                      <TbBrandBinance style={{fontSize:"1.5rem"}} />
                    </div>
                    <div className="sigin_logos">
                      <FaFacebookF style={{fontSize:"1.5rem"}} />
                    </div>
                    <div className="sigin_logos">
                      <FaTelegramPlane style={{fontSize:"1.5rem"}} />
                    </div>
                  </div>
                  <p className="text-sm font-light text-white text-center site_font">
                    Don’t have an account yet?{" "}
                    <Link
                      to="/SingUp"
                      style={{ color: "#5177FC" }}
                      className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                    >
                      Sign up
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
