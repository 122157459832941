import { Navigate } from 'react-router-dom';

const PrivateRoutes = ({ children }) => {
  const isUserAuthenticated = sessionStorage.getItem('Auth');

  if (isUserAuthenticated !=="true") {
    return <Navigate to="/LogIn" />;
  }

  return children;
};

export default PrivateRoutes;
