import logo from './logo.svg';
import './App.css';

import Landing_page from './Components/Landing_page/Landing_page';
import toast, { Toaster } from 'react-hot-toast';
import Home_page from './Components/Home_page/Home_page';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header_meta from './Components/Header_meta/Header_meta';
import Footer_meta from './Components/Footer_meta/Footer_meta';
import Token_page from './Components/Token_page/Token_page';
import Refferal_page from './Components/Refferal_page/Refferal_page';
import Home_landing from './Components/Home_landing/Home_landing';
import Profile_page from './Components/Profile_page/Profile_page';
import DashBoard from './Pages/DashBoard/DashBoard';
import Auth from './Pages/Auth/Auth';
import SingUp from './Pages/Auth/SingUp';
import PrivateRoutes from './utils/PrivateRoutes';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';

const GTM_ID = 'GTM-M2ZR6MVK';
function App() {
  const location = useLocation();
  const isHomePage = location.pathname  ;
  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: location.pathname + location.search,
      },
    });
  }, [location]);
  return (
    <div className="App">
      <Toaster />
      <>
        {
          isHomePage =="/" || isHomePage=="/dashboard" ? null : <Header_meta />
        }
        <Routes>
          <Route path="/" element={<Home_page />} />
          <Route path="Refferal_page" element={<Refferal_page />} />
          <Route path="staking" element={<Landing_page />} />
          <Route path="buy_token" element={<Token_page />} />
          <Route path="proflie_page" element={<Profile_page />} />
          <Route path="LogIn" element={<Auth /> } />
          <Route path="/dashboard" element={<PrivateRoutes><DashBoard /></PrivateRoutes>} />
          <Route path="/SingUp" element={<SingUp />} />




        </Routes>
      </>
      {
          isHomePage=="/dashboard" ? null : <Footer_meta />
        }


    </div>
  );
}

export default App;
