import React from "react";
import "./Crpto_invest.css";
import laptop from "../Assets/laptop.png";
import { Link } from "react-router-dom";
export default function Crpto_invest() {
  return (
    <div className="main_crpto_inves_page">
      <div className="overlay1"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 z_upper_full">
            <h1 className="crptohead site_font">
              Infinite Protocol is the Next-gen Web 3.0 Crypto Investment
              Platform
            </h1>

            <ul className="feature_ul">
              <li className="site_font">
                Infinite Protocol offers an intuitive non-custodial crypto
                investment experience guided by blockchain AI execution
                interface.
              </li>
              <li className="site_font">
                Profitable strategies featuring controllable risk levels, along
                with a transparent and traceable history of every deal.
              </li>
              <li className="site_font">
                Unique AI trading and investment tools powered by an AI
                execution technology.
              </li>
              <li className="site_font">
                Access to crypto investments across DeFi, CeFi, and NFT markets.
              </li>
            </ul>
            <Link to="/LogIn" className="text-decoration-none " onClick={()=>window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <button className="playbtn site_font mt-4">Join Now</button>
            </Link>
          </div>
          <div className="col-md-6 z_upper_full">
            <img src={laptop} className="w-100" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
