import React from "react";
import "./About_team.css";
import team from "../Assets/team.png";
import team1 from "../Assets/Daniel.png" 
import team2 from "../Assets/Sven.png"
import team3 from "../Assets/Juli.png" 
import team4 from "../Assets/Greg.png" 
import steh from "../Assets/steph.png"
export default function About_team() {
  return (
    <div className="about_team_main" id="Team">
    <div className="overlay_team"></div>
      <div className="container">
      <div className="row justify-center">
        <div className="col-md-6 z_upper_full">

      <h6 className="team_eadings site_font">The Infinite Protocol TEAM</h6>
      <h1 className="site_font meet_teamm">Meet our leadership team</h1>
        </div>
      </div>
       
        <div className="row ">
          <div className="col-md-8 z_upper_full">
            <div className="content_team mt-5">
            

              <div className="d-flex gap-4 align-items-center  mt-5">
              <div>
                
              <img src={team4} className="team_member_img" alt="" />
              </div>
              <div>

                 <p className="cheif_ex site_font ">“We’re here to improve lives. If that means more work for us, or that we make less money, we will always take the more difficult path in favour of treating people better.”</p>
                <h2 className="text-left tema_ceo site_font mt-3">Walid Malash & CEO</h2>
              </div>
              </div>
            
            </div>
          </div>
        </div>

        <div className="row mt-5">
      

          <div className="col-md-3 z_upper_full">
            <div className=" ">
              
              <img src={team1} className="team_member_img" alt="" />
            <h1 className="team_mem  mt-4 site_font">Adel</h1>
            <p className="about_member site_font">
             Manager
            </p>
            </div>
          
          </div>
          <div className="col-md-3 z_upper_full mt-3 mt-md-0">
            <div className=" ">
              
              <img src={team2} className="team_member_img" alt="" />
            <h1 className="team_mem site_font mt-4">Dainel</h1>
            <p className="about_member site_font">
             Manager
            </p>
            </div>
          
          </div>
          <div className="col-md-3 z_upper_full mt-3 mt-md-0">
            <div className=" ">
              
              <img src={team3} className="team_member_img" alt="" />
            <h1 className="team_mem site_font mt-4">Alisha</h1>
            <p className="about_member site_font">
             Manager
            </p>
            </div>
          
          </div>
          <div className="col-md-3 mt-3 z_upper_full mt-md-0">
            <div className=" ">
              
              <img src={steh} className="team_member_img" alt="" />
            <h1 className="team_mem site_font mt-4">Stephine Kater </h1>
            <p className="about_member site_font">
             Manager
            </p>
            </div>
          
          </div>
         
        </div>

        <div></div>
      </div>
    </div>
  );
}
