import React, { useEffect, useState } from "react";
import { FaBtc, FaEthereum } from "react-icons/fa";
import { FaBitcoinSign, FaLitecoinSign } from "react-icons/fa6";
import { SiRipple } from "react-icons/si";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import { IoMenu } from "react-icons/io5";
import logo from "../../Components/Assets/site_logo.png";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { etherUnits } from "viem";

export default function DashBoard() {
  const [active, setactive] = useState(false);
  const { address } = useAccount();
  let Navigate = useNavigate();
  const [showBalance, setshowBalance] = useState(0);
  const [Get_Ethereum_Price, setGet_Ethereum_Price] = useState({
    priceeth: 0,
    changeeth: 0,
    pricebtc:0,
    changebtc:0
  });
  let userData = sessionStorage.getItem("userData");
  const webSupply = new Web3("https://bsc-rpc.publicnode.com");

  const getBalance = async () => {
    if (address) {
      let balance = await webSupply.eth.getBalance(address);
      console.log("balance", balance);
      balance = webSupply.utils.fromWei(balance.toString());
      setshowBalance(balance);
    }
  };

  const getLivePrice = async () => {
    try {
      let ethPrice = await axios.get(
        "https://archievault.archievault.net/Live_Rate?Coin=ethereum"
      );
      ethPrice = ethPrice.data.data.data;
      ethPrice = Object.values(ethPrice);
      let change24 = ethPrice[0].quote.USD.percent_change_24h;
      console.log("ethPrice", change24);
      ethPrice = ethPrice[0].quote.USD.price;


      let bitCoinPrice = await axios.get(
        "https://archievault.archievault.net/Live_Rate?Coin=bitcoin"
      );
      bitCoinPrice = bitCoinPrice.data.data.data;
      bitCoinPrice = Object.values(bitCoinPrice);
      let btcChange24 = bitCoinPrice[0].quote.USD.percent_change_24h;
      console.log("bitCoinPrice", change24);
      bitCoinPrice = bitCoinPrice[0].quote.USD.price;
      setGet_Ethereum_Price({
        ...Get_Ethereum_Price,
        priceeth: ethPrice,
        changeeth: change24,
        pricebtc: bitCoinPrice,
        changebtc: btcChange24,
      });

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLivePrice();
    getBalance();
  }, [address]);

  return (
    <div>
      <div className={active ? "App nav-mini" : "App"}>
        <div className="header fixed-top">
          <div className="nav-header">
            <div className="brand-logo">
              <a href="/" style={{ display: "flex" }}>
                {/* <img
                  src={logo} alt="logo"
                /> */}
                <span className="brand-title ">Infinite Presale</span>
              </a>
            </div>
          </div>
          <div className="header-content">
            <div className="header-left d-flex">
              <div className="nav-control" onClick={() => setactive(!active)}>
                <IoMenu style={{ fontSize: "2rem" }} />
              </div>
              <div className="nav-search-box">
                <i className="fa fa-search" />
                <input type="search" placeholder="Search" />
              </div>
            </div>
          </div>
          <div className="navbar-custom-menu pull-right d-flex nav-right">
            <div className="dropdown">
              <div
                className="dropdown-toggle"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <a className="dropdown-toggle" href="#">
                  <span className="badge badge-danger">
                    <span />
                  </span>
                  <i className="fa fa-envelope" />
                </a>
              </div>
            </div>
            <div className="dropdown">
              <div
                className="dropdown-toggle"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <a className="dropdown-toggle" href="#">
                  <span className="badge badge-danger">
                    <span />
                  </span>
                  <i className="fa fa-bell" />
                </a>
              </div>
            </div>
            <div className="dropdown">
              <div
                className="dropdown-toggle"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <a
                  className="dropdown-toggle"
                  href="#"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-cog" />
                </a>
              </div>
            </div>
            <div className="dropdown">
              <div
                className="dropdown-toggle"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <button
                  onClick={() => {
                    sessionStorage.setItem("Auth", null);
                    Navigate("/LogIn");
                  }}
                >
                  LogOut
                </button>
              </div>
            </div>
            <div className="dropdown">
              <div
                className="user-profile-dropdown dropdown-toggle"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <a
                  className="dropdown-toggle user-profile-dropdown"
                  href="#"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {/* <span className="user-img">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAMAAABgOjJdAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAANlBMVEW8vLx6enpsbGx1dXVzc3Nvb29hYWF0dHSIiIigoKBZWVmqqqopKSlmZmZ3d3dbW1uKior+/v4ipiuGAAAAAWJLR0QR4rU9ugAAADpJREFUOMtjYBgFwx8wMjEDETIDA7CwAhEbOweEgUUBJwMXEHHzQBnYjODlAyJ+AUYIY6A9PQoGBQAA3dkBUdnDxuMAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMDgtMDlUMTA6MDQ6MDgtMDU6MDBRbHbQAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTA4LTA5VDEwOjA0OjA4LTA1OjAwIDHObAAAAABJRU5ErkJggg=="
                      alt="user"
                    />
                  </span> */}
                  <span className="user-name">{userData}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-sidebar">
          <div className="nk-nav-scroll">
            <div
              data-simplebar="init"
              style={{ maxHeight: 633 }}
              className="simplebar-scrollable-y"
            >
              <div className="simplebar-wrapper" style={{ margin: 0 }}>
                <div className="simplebar-height-auto-observer-wrapper">
                  <div className="simplebar-height-auto-observer" />
                </div>
                <div className="simplebar-mask">
                  <div
                    className="simplebar-offset"
                    style={{ right: 0, bottom: 0 }}
                  >
                    <div
                      className="simplebar-content-wrapper"
                      style={{ height: "auto", overflow: "hidden scroll" }}
                    >
                      <div className="simplebar-content" style={{ padding: 0 }}>
                        <ul className="metismenu">
                          <li className="pt-3">
                            <a aria-current="page" className="active" href="/">
                              <i className="fa fa-dashboard" />
                              <span className="nav-text">Dashboard</span>
                            </a>
                          </li>
                          <li className="linknav">
                            <a href="/trading">
                              <i className="fa fa-line-chart" />
                              <span className="nav-text">Trading</span>
                            </a>
                          </li>
                          <li className="linknav">
                            <a href="/marketcap">
                              <i className="fa fa-bolt" />
                              <span className="nav-text">Marketcap</span>
                            </a>
                          </li>
                          <li className="linknav">
                            <a href="/ico">
                              <i className="fa fa-btc" />
                              <span className="nav-text">ICO</span>
                            </a>
                          </li>
                          <li className="linknav">
                            <a href="/buy_sell">
                              <i className="fa fa-usd" />
                              <span className="nav-text">Buy and Sell</span>
                            </a>
                          </li>
                          <li className="linknav">
                            <a href="/wallet">
                              <i className="fa fa-credit-card" />
                              <span className="nav-text">My Wallet</span>
                            </a>
                          </li>
                          <li className="linknav">
                            <a href="/currency_exchange">
                              <i className="fa fa-refresh" />
                              <span className="nav-text">
                                Currency Exchange
                              </span>
                            </a>
                          </li>
                          <li className="linknav">
                            <a
                              className="has-arrow"
                              href="#"
                              aria-expanded="false"
                            >
                              <i className="fa fa-folder-open" />
                              <span className="nav-text">UI Elements</span>
                            </a>
                            <ul aria-expanded="false" className="mm-collapse">
                              <li className="linknav">
                                <a href="/grids">
                                  <span className="nav-text-2">Grids</span>
                                </a>
                              </li>
                              <li className="linknav">
                                <a href="/buttons">
                                  <span className="nav-text-2">Buttons</span>
                                </a>
                              </li>
                              <li className="linknav">
                                <a href="/tables">
                                  <span className="nav-text-2">Tables</span>
                                </a>
                              </li>
                              <li className="linknav">
                                <a href="/tabs">
                                  <span className="nav-text-2">Tabs</span>
                                </a>
                              </li>
                              <li className="linknav">
                                <a href="/forms">
                                  <span className="nav-text-2">Forms</span>
                                </a>
                              </li>
                              <li className="linknav">
                                <a href="/charts">
                                  <span className="nav-text-2">Charts</span>
                                </a>
                              </li>
                              <li className="linknav">
                                <a href="/pagination">
                                  <span className="nav-text-2">Pagination</span>
                                </a>
                              </li>
                            </ul>
                          </li>
                          {/* <li className="linknav">
                            <a href="/register">
                              <i className="fa fa-key" />
                              <span className="nav-text">Register</span>
                            </a>
                          </li>
                          <li className="linknav">
                            <a href="/login">
                              <i className="fa fa-user" />
                              <span className="nav-text">Login</span>
                            </a>
                          </li>
                          <li className="linknav">
                            <a href="/recover_password">
                              <i className="fa fa-lock" />
                              <span className="nav-text">Recover Password</span>
                            </a>
                          </li>
                          <li className="linknav">
                            <a href="/reset_password">
                              <i className="fa fa-lock" />
                              <span className="nav-text">Reset Password</span>
                            </a>
                          </li> */}
                          <li className="linknav">
                            <a href="/faq">
                              <i className="fa fa-question-circle" />
                              <span className="nav-text">FAQ</span>
                            </a>
                          </li>
                          <li className="linknav">
                            <a href="/error">
                              <i className="fa fa-exclamation-circle" />
                              <span className="nav-text">Error</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="simplebar-placeholder"
                  style={{ width: 250, height: 679 }}
                />
              </div>
              <div
                className="simplebar-track simplebar-horizontal"
                style={{ visibility: "hidden" }}
              >
                <div
                  className="simplebar-scrollbar"
                  style={{
                    width: 0,
                    display: "none",
                    transform: "translate3d(0px, 0px, 0px)",
                  }}
                />
              </div>
              <div
                className="simplebar-track simplebar-vertical"
                style={{ visibility: "visible" }}
              >
                <div
                  className="simplebar-scrollbar"
                  style={{
                    height: 590,
                    transform: "translate3d(0px, 0px, 0px)",
                    display: "block",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6">
                <h2 className="page-title fs-3 fw-bold">Your Currencies</h2>
                <p>The currencies you have purchased are here.</p>
                <div
                  className="currency-icons d-flex"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="cc BTC"
                      style={{ backgroundColor: "#f7931a" }}
                    >
                      <FaBtc style={{ color: "#fff" }} />
                    </i>
                  </div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="cc BTC"
                      style={{ backgroundColor: "#345281" }}
                    >
                      <FaEthereum style={{ color: "#fff" }} />
                    </i>
                  </div>

                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="cc BTC"
                      style={{ backgroundColor: "#1277a8" }}
                    >
                      <SiRipple style={{ color: "#fff" }} />
                    </i>
                  </div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="cc BTC"
                      style={{ backgroundColor: "#58bf00" }}
                    >
                      <FaLitecoinSign style={{ color: "#fff" }} />
                    </i>
                  </div>
                  <div>
                    <i className="fa fa-plus" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div id="doughutChart">
                  <div className="chart-2">
                    <div className="chartjs-size-monitor">
                      <div className="chartjs-size-monitor-expand">
                        <div className="" />
                      </div>
                      <div className="chartjs-size-monitor-shrink">
                        <div className="" />
                      </div>
                    </div>
                    <PieChart />
                    {/* <canvas
                      height={330}
                      width={"100%"}
                      style={{ display: "block", width: "100%", height: 330 }}
                      className="chartjs-render-monitor"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-t-25">
              {/* <div className="col-lg-3">
                <div className="card currency-card-rounded">
                  <div className="card-body rounded bitcoin">
                    <div className="currency-card--icon pull-right">
                      <i className="cc BTC-alt" title="BTC">
                        <FaBitcoinSign />
                      </i>
                    </div>
                    <h4>Bitcoin</h4>
                    <h2>
                      <span>1.765</span> BTC
                    </h2>
                    <p>Brought Rate: 25%</p>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-3">
                <div className="card currency-card-rounded">
                  <div className="card-body rounded ethereum">
                    <div className="currency-card--icon pull-right">
                      <i className="cc ETH-alt" title="ETH">
                        <FaEthereum />
                      </i>
                    </div>
                    <h4>Ethereum</h4>
                    <h2>
                      <span>{showBalance}</span> ETH
                    </h2>
                    <p>Brought Rate: 50%</p>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "50%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3">
                <div className="card currency-card-rounded">
                  <div className="card-body rounded ripple">
                    <div className="currency-card--icon pull-right">
                      <i className="cc XRP-alt" title="XRP">
                        <SiRipple />
                      </i>
                    </div>
                    <h4>Ripple</h4>
                    <h2>
                      <span>1.765</span> BTC
                    </h2>
                    <p>Brought Rate: 75%</p>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "75%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="card currency-card-rounded">
                  <div className="card-body rounded litecoin">
                    <div className="currency-card--icon pull-right">
                      <i className="cc LTC-alt" title="LTC">
                        <FaLitecoinSign />
                      </i>
                    </div>
                    <h4>Litecoin</h4>
                    <h2>
                      <span>1.765</span> BTC
                    </h2>
                    <p>Brought Rate: 45%</p>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "45%" }}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row m-t-25">
              <div className="col-lg-4">
                <div className="table-data crypto-price-card accordion">
                  <div className="card">
                    <div className="card-header">
                      <div variant="link">
                        <h4>
                          Current Pricing
                          <i className="fa fa-angle-up pull-right " />
                        </h4>
                      </div>
                    </div>
                    <div>
                      <div className="card-body">
                        <div className="pricing-wrapper">
                          <table className="table m-b-0">
                            <thead>
                              <tr className="search-block">
                                <th scope="col">Show 15</th>
                                <th colSpan={2} scope="col">
                                  <span>Search</span>
                                  <input placeholder="Search" type="search" />
                                </th>
                              </tr>
                              <tr>
                                <th scope="col">Coins</th>
                                <th scope="col">Prices</th>
                                <th scope="col">Change %</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>BTC</td>
                                <td>$ {parseFloat(
                                    Get_Ethereum_Price?.pricebtc
                                  ).toFixed(3)}</td>
                                <td className="change">
                                  <i className="fa fa-angle-up" />
                                  <span>{parseFloat(
                                    Get_Ethereum_Price?.changebtc
                                  ).toFixed(3)}%</span>
                                </td>
                              </tr>
                              <tr>
                                <td>ETH</td>
                                <td>
                                  $
                                  {parseFloat(
                                    Get_Ethereum_Price?.priceeth
                                  ).toFixed(3)}
                                </td>
                                <td className="change">
                                  <i className="fa fa-angle-up" />
                                  <span>
                                    {parseFloat(
                                      Get_Ethereum_Price?.changeeth
                                    ).toFixed(4)}
                                    %
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>LTC</td>
                                <td>$12,623.40</td>
                                <td className="change">
                                  <i className="fa fa-angle-up" />
                                  <span>+6.50%</span>
                                </td>
                              </tr>
                              <tr>
                                <td>XRP</td>
                                <td>$12,623.40</td>
                                <td className="change">
                                  <i className="fa fa-angle-up" />
                                  <span>+6.50%</span>
                                </td>
                              </tr>
                              <tr>
                                <td>DASH</td>
                                <td>$12,623.40</td>
                                <td className="change">
                                  <i className="fa fa-angle-up" />
                                  <span>+6.50%</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-8">
                <div className="crypto-chart-card accordion">
                  <div className="card">
                    <div className="card-header">
                      <div variant="link">
                        <h4>
                          Currency Chart
                          <i className="fa fa-angle-up pull-right " />
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <BarChart />
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-t-25">
              <div className="col-lg-3">
                <div className="crypto-price-card accordion">
                  <div className="card">
                    <div className="card-header">
                      <div variant="link">
                        <h4>
                          {" "}
                          Live Crypto
                          <i className="fa fa-angle-up pull-right " />
                        </h4>
                      </div>
                    </div>
                    <div>
                      <div className="card-body">
                        <ul className="m-b-0">
                          <li>
                            <span className="currency-name BTC">BTC</span>
                            <span className="currency-status pull-right">
                              $8.114
                              <i className="fa fa-angle-down" />
                            </span>
                          </li>
                          <li>
                            <span className="currency-name ETH">ETH</span>
                            <span className="currency-status pull-right">
                              $8.114
                              <i className="fa fa-angle-up" />
                            </span>
                          </li>
                          <li>
                            <span className="currency-name XRP">XRP</span>
                            <span className="currency-status pull-right">
                              $8.114
                              <i className="fa fa-angle-up" />
                            </span>
                          </li>
                          <li>
                            <span className="currency-name NEO">NEO</span>
                            <span className="currency-status pull-right">
                              $8.114
                              <i className="fa fa-angle-down" />
                            </span>
                          </li>
                          <li>
                            <span className="currency-name">BTC</span>
                            <span className="currency-status pull-right">
                              $8.114
                              <i className="fa fa-angle-up" />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="crypto-transfer-card accordion">
                  <div className="card">
                    <div className="card-header">
                      <div variant="link">
                        <h4>
                          Transfer Coin
                          <i className="fa fa-angle-up pull-right " />
                        </h4>
                      </div>
                    </div>
                    <div>
                      <div className="card-body">
                        <form action="#">
                          <div className="input-group m-b-30">
                            <div className="input-group-prepend">
                              <div className="input-group-text">$</div>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              name="choose_coin"
                              placeholder="Enter Amount"
                              required=""
                            />
                            <span className="input-dropdown">
                              <select name="" id="">
                                <option value="BTC">BTC</option>
                                <option value="ETC">ETC</option>
                                <option value="XHR">XHR</option>
                              </select>
                            </span>
                          </div>
                          <div className="input-group m-b-30">
                            <div className="input-group-prepend">
                              <div className="input-group-text">$</div>
                            </div>
                            <input
                              type="text"
                              name="coin_amount"
                              className="form-control"
                              required=""
                            />
                          </div>
                          <button
                            className="btn btn-success button"
                            style={{ backgroundColor: "#1e9279" }}
                            type="submit"
                          >
                            Send
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="walet-address-card accordion">
                  <div className="card">
                    <div className="card-header">
                      <div variant="link">
                        <h4>
                          Walet Address
                          <i className="fa fa-angle-up pull-right " />
                        </h4>
                      </div>
                    </div>
                    <div>
                      <div className="card-body">
                        <div className="card-body-2">
                          <h6>Bitcoin wallet address</h6>
                          <div className="input-group m-b-20">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="cc BTC" aria-hidden="true" />
                              </div>
                            </div>
                            <input
                              type="text"
                              className="form-control walet-address"
                            />
                            <span className="input-group-addon">Copy</span>
                          </div>
                          <h6 className="m-t-20">Ethereum Walet Address</h6>
                          <div className="input-group m-b-20">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="cc ETC" aria-hidden="true" />
                              </div>
                            </div>
                            <input
                              type="text"
                              className="form-control walet-address"
                            />
                            <span className="input-group-addon" id="">
                              Copy
                            </span>
                          </div>
                          <h6 className="m-t-20">Ripple Walet Address</h6>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="cc XRP" aria-hidden="true" />
                              </div>
                            </div>
                            <input
                              type="text"
                              className="form-control walet-address"
                            />
                            <span className="input-group-addon">Copy</span>
                          </div>
                        </div>
                        {/* <div className="walet-direction">
                          <h4 className="mb-0">
                            <a href="">Go to Wallet</a>
                          </h4>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="crypto-price-card accordion">
                  <div className="card">
                    <div className="card-header">
                      <div variant="link">
                        <h4>
                          Recent Transactions
                          <i className="fa fa-angle-up pull-right " />
                        </h4>
                      </div>
                    </div>
                    <div>
                      <div className="card-body">
                        <table className="table m-b-0">
                          <thead>
                            <tr>
                              <th scope="col">Price</th>
                              <th scope="col">Amount</th>
                              <th scope="col">When</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>12,623.40</td>
                              <td>$12,623.40</td>
                              <td className="change">
                                <span>21 min age</span>
                              </td>
                            </tr>
                            <tr>
                              <td>12,623.40</td>
                              <td>$12,623.40</td>
                              <td className="change">
                                <span>21 min age</span>
                              </td>
                            </tr>
                            <tr>
                              <td>12,623.40</td>
                              <td>$12,623.40</td>
                              <td className="change">
                                <span>21 min age</span>
                              </td>
                            </tr>
                            <tr>
                              <td>12,623.40</td>
                              <td>$12,623.40</td>
                              <td className="change">
                                <span>21 min age</span>
                              </td>
                            </tr>
                            <tr>
                              <td>12,623.40</td>
                              <td>$12,623.40</td>
                              <td className="change">
                                <span>21 min age</span>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                {/* <div className="walet-direction">
                                  <h4 className="mb-0 text-center">
                                    <a href="">Go to Trade</a>
                                  </h4>
                                </div> */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <p className="copyright">
                © Copyright 2020 by Tortoiz. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
