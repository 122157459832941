import React from "react";
import "./Features.css";
import feature from "../Assets/feature_bg.png";
import fea from "../Assets/fea.png";
import featrue_mb from "../Assets/feature_mobile.png";
import bit from "../Assets/bybit.png";
import kucoin from "../Assets/kucoin.png";
import dao from "../Assets/dao.png";
import shima from "../Assets/shima.png";
import bia from "../Assets/binance.png";
import chaingpt from "../Assets/chaingpt.png";
import redswiss from "../Assets/redswiss.png";
import m1 from "../Assets/m1.jpg"
import m2 from "../Assets/m2.png"
import video from "../Assets/playvideo.mp4";
// import g12 from "../Assets/"

import { FaLongArrowAltRight } from "react-icons/fa";
import apk from "../Assets/apk.png";
import bnb from "../Assets/bnb.png";
import gold from "../Assets/gold.png";
import sliver from "../Assets/silver.png";

import { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import app from "../Assets/app-store.png";
import gate from "../Assets/gate.png";
import bitget from "../Assets/bitget.png";
import { Swiper, SwiperSlide } from "swiper/react";
import pancake from "../Assets/pancake.png";
import mex from "../Assets/mex.png";
import htx from "../Assets/htx.png";
export default function Features() {
  return (
    <div className="main_features_page">
      <div className="container  ">
        <img src={feature} className="feature_bg" alt="" />
        <h6
          className="position-relative z_upper_full text-white my-10 avail_trust_head"
          id="Partners"
        >
          AVAILABLE ON:
        </h6>
        <div className="row availscar ">
          <>
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 7,
                  spaceBetween: 10,
                },
              }}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper position-relative z_upper_full"
            >
              <SwiperSlide className="mainImages">
                <img src={bit} className="slider_imgs_heea" alt="" />
              </SwiperSlide>
              <SwiperSlide className="mainImages">
                <img src={kucoin} className="slider_imgs_heea" alt="" />
              </SwiperSlide>
              <SwiperSlide className="mainImages">
                <img src={gate} className="slider_imgs_heea" alt="" />
              </SwiperSlide>
              <SwiperSlide className="mainImages">
                <img src={bitget} className="slider_imgs_heea" alt="" />
              </SwiperSlide>
              <SwiperSlide className="mainImages">
                <img src={pancake} className="slider_imgs_heea" alt="" />
              </SwiperSlide>
              <SwiperSlide className="mainImages">
                {" "}
                <img src={mex} className="slider_imgs_heea" alt="" />
              </SwiperSlide>
              <SwiperSlide className="mainImages">
                {" "}
                <img src={htx} alt="" />
              </SwiperSlide>
              <SwiperSlide className="mainImages">
                <img src={bit} className="slider_imgs_heea" alt="" />
              </SwiperSlide>
              <SwiperSlide className="mainImages">
                <img src={kucoin} className="slider_imgs_heea" alt="" />
              </SwiperSlide>

            </Swiper>
          </>
        </div>
        <h6 className="position-relative z_upper_full text-white my-10 avail_trust_head ">
          TRUSTED BY:
        </h6>
        <div className="row availscar pt-4 pb-4">
          <>
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                },
              }}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper position-relative z_upper_full"
            >
              <SwiperSlide>
                <img src={dao} alt="" className="slider_imgs_heea" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={shima} alt="" className="slider_imgs_heea" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={bia} alt="" className="slider_imgs_heea" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={dao} alt="" className="slider_imgs_heea" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={chaingpt} alt="" className="slider_imgs_heea" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <img src={redswiss} alt="" className="slider_imgs_heea" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={dao} alt="" className="slider_imgs_heea" />{" "}
              </SwiperSlide>
              <SwiperSlide>
                <img src={bia} alt="" className="slider_imgs_heea" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={chaingpt} alt="" className="slider_imgs_heea" />
              </SwiperSlide>
              {/* <SwiperSlide>Slide 8</SwiperSlide> */}
              {/* <SwiperSlide>Slide 9</SwiperSlide> */}
            </Swiper>
          </>
        </div>
        <div className="container">
        <h1 className='position-relative z_upper_full text-white my-10 avail_trust_head'>Infinite Protocol is KYC by  <a href="https://solidproof.io/" target="_blank" style={{fontSize:"22px"}}>Solidproof.io </a>  <br/>
        Audit by<a href="https://solidproof.io/" target="_blank" style={{fontSize:"22px"}}> Solidproof.io  </a> and <a href="https://www.certik.com/" target="_blank" style={{fontSize:"22px"}}> Certik </a></h1>
        <div className="row  justify-content-center my-6">
            <div className="col-md-4 mt-3 mt-md-0">
                <div className="muti_chain_box">
                    <img src={m1} className='w-100' alt="" />
                </div>
            </div>
            <div className="col-md-4 mt-3 mt-md-0">
                <div className="muti_chain_box">
                    <img src={m2} className='w-100' alt="" />
                </div>
            </div>
            {/* <div className="col-md-4 mt-3 mt-md-0">
                <div className="muti_chain_box">
                    <img src={m3} className='w-100' alt="" />
                </div>
            </div> */}
        </div>
        </div>
        <h1 className="feature_head site_font my-10">Features</h1>
        <div className="row mt-5 align-items-center">
          <div className="col-md-6 z_upper_full">
            <div className="imgTextClass">
              <img src={fea} className="feam_img d-none d-lg-block" alt="" />
              <span className="featrei_samll d-none d-lg-block site_font">
                Features
              </span>
            </div>
            <div>
              <h1 className="ai_crypto site_font">
                AI crypto <br />
                management
              </h1>
              <a href = {video} target = "_blank"> <button className="playbtn site_font">Play video</button></a>
              <ul className="feature_ul">
                <li className="site_font">
                  Use AI-Powered Portfolio Management.
                </li>
                <li className="site_font">
                  Get benefits from AI-Powered Auto Trading.
                </li>
                <li className="site_font">
                  Use AI to search for the best opportunities in crypto
                  investment deals (IDO, Staking, Copy Trading, etc.)
                </li>
                <li className="site_font">
                  Conduct in-Depth on-chain and off-chain AI market analysis
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 z_upper_full">
            <img src={featrue_mb} className="w-100" alt="" />
          </div>
        </div>
      </div>

      <div className="brain_bg_section"></div>
    </div>
  );
}
