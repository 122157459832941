import React from 'react'
import "./HowToBuy.css"

export default function HowToBuy() {
  return (
    <div className='HowToBuy'>
        <div className="container">
        <h1 className='text-white site_font fw-bold text-center fs-1 mb-3'>How To Buy</h1>
            <div className="row">
                <div className="col-md-4 text-center how_to_buy_box">
                   <div className='d-flex justify-center'>
                    <div className='number_cir'>1</div>
                    </div>
                    <h1 className='text-white fw-bold site_font '>Connect Wallet</h1>
                    <p className='text-white site_font fw-normal'>Find the presale widget at the top of this web page and connect your preferred wallet.</p>
                  
                </div>
                <div className="col-md-4 text-center how_to_buy_box">
                   <div className='d-flex justify-center'>
                    <div className='number_cir'>2</div>
                    </div>
                    <h1 className='text-white fw-bold site_font '>Select Binance Smart Chain</h1>
                    <p className='text-white site_font fw-normal'>Once connected, you can participate in the presale on Binance Smart Chain.</p>
                  
                </div>
                <div className="col-md-4 text-center how_to_buy_box">
                   <div className='d-flex justify-center'>
                    <div className='number_cir'>3</div>
                    </div>
                    <h1 className='text-white fw-bold site_font '>Buy Tokens</h1>
                    <p className='text-white site_font fw-normal'>Enter the amount of BNB/ USDT/ USDC you wish to swap for $INFAI  and confirm the transaction in your wallet.</p>
                  
                </div>
               
                
            </div>
        </div>
    </div>
  )
}
