import React from "react";
import "./Landing_page.css";

import Xbit_usdt from "../Xbit_usdt/Xbit_usdt";


export default function Landing_page() {
  return (
    <div className="main_landing">
      <div className="overlay11"></div>
      <div className="container ">
        <div className="">
          <h1 class="heading heading--accent header-04__heading text-white  site_font z_upper_full position-relative ">
            {" "}
            $INFAI - Empowering Your Financial Freedom
          </h1>
          <div class="header-04__text content_box  meta_site_text">
            <h2 className="position-relative text-white site_font z_upper_full">
              <strong>
                Imagine a utility token that actually has utility{" "}
              </strong>
            </h2>
          </div>
        </div>

        <Xbit_usdt />
      </div>
    </div>
  );
}
