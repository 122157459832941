import React from 'react'
import Home_landing from '../Home_landing/Home_landing'
import FAQ from "../FAQ/FAQ"
import About_team from "../About_team/About_team"
import RoadMap_links from '../RoadMap_links/RoadMap_links'
import Partners from '../Partners/Partners'
import Presale_stage from '../Presale_stage/Presale_stage'
import Tokenomics from '../Tokenomics/Tokenomics'
import Refferal_system from '../Refferal_system/Refferal_system'
import Features from '../Features/Features'
import Crpto_invest from '../Crpto_invest/Crpto_invest'
import Protection from '../Protection/Protection'
import Technology from '../Technology/Technology'
import HowToBuy from '../HowToBuy/HowToBuy'

export default function Home_page() {
  return (
    <div>
     <Home_landing/>
     <HowToBuy/>
     <Features/>
     <Crpto_invest/>
     <Protection/>
     <Technology/>
     <Tokenomics/>
     <RoadMap_links/>
     {/* <Presale_stage/> */}
     <About_team/>
     {/* <Refferal_system/> */}
     {/* <FAQ/> */}
     {/* <Partners/> */}
   
    
    
    </div>
  )
}
