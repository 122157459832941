import React, { useEffect, useState } from "react";
import "./Profile_page.css";
import pro_img from "../Assets/user-avatar.png";
import { FaCopy } from "react-icons/fa";
import { BiSolidMessageSquare } from "react-icons/bi";
import site from "../Assets/site_logo.png";
import gold from "../Assets/gold.png";
import { FaCircleInfo } from "react-icons/fa6";
// import { FaCopy } from "react-icons/fa6";
import Accordion from "react-bootstrap/Accordion";
import sfa from "../Assets/site_logo.png";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Link } from "react-router-dom";
import { PresaleEthV2_ABI, PresaleEthV2_Address } from "../../utilies/constant";
import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";
import Web3 from "web3";
import toast from "react-hot-toast";

export default function Profile_page() {
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const [refAddress, setRefAddress] = useState("");
  const [copied, setCopied] = useState(false);
  const [getdepositsLeng, setgetdepositsLeng] = useState([]);
  const [spinner, setspinner] = useState(false);
  const [stagePrice_show, setstagePrice_show] = useState(0);
  const [totalMetaAmount, settotalMetaAmount] = useState(0);
  const [referalInfo, setReferalInfo] = useState(0);
  const [referalinfoclaimed, setreferalinfoclaimed] = useState(0);
  const [claimable, setClaimable] = useState(0);

  const [isReferalClaim, setIsReferalClaim] = useState(false);
  const [claimSpinner, setclaimSpinner] = useState(false);
  const [usdtspent, setusdtspent] = useState(false);
  const [depositenteries, setdepositenteries] = useState(false);

  let history = window.location;

  const webSupply = new Web3("https://bsc-rpc.publicnode.com");
  const deposits = async () => {
    let contractOf = new webSupply.eth.Contract(
      PresaleEthV2_ABI,
      PresaleEthV2_Address
    );
    let array = [];
    let claimAbleAmountArray = 0;
    if (address) {
      let deposits = await contractOf.methods.deposits(address).call();
      let depositenter = await contractOf.methods
        .checkDepositEnteries(address)
        .call();
      let usdtsspent = await contractOf.methods.checkUSDTspent(address).call();
      usdtsspent = webSupply.utils.fromWei(usdtsspent.toString());
      setusdtspent(usdtsspent);
      setdepositenteries(depositenter);

      // console.log("deposits", deposits.length);
      let claimtime = await contractOf.methods.claimStart().call();
      if (claimtime > 0) {
        for (let i = 0; i < deposits.length; i++) {
          let getClaimableAmount = await contractOf.methods
            .getClaimableAmount(address, i)
            .call();
          if (getClaimableAmount > 0) {
            array.push(i);
          }
          getClaimableAmount = webSupply.utils.fromWei(
            getClaimableAmount.toString()
          );
          claimAbleAmountArray =
            Number(claimAbleAmountArray) + Number(getClaimableAmount);
        }
      }
      // claimAbleAmountArray= webSupply.utils.fromWei(claimAbleAmountArray.toString())
      console.log("claimAbleAmountArray", claimAbleAmountArray);
      settotalMetaAmount(parseFloat(claimAbleAmountArray).toFixed(2));
      setgetdepositsLeng(array);
      let referalinfo = await contractOf.methods.referalinfo(address).call();
      let isReferalClaimOpen = await contractOf.methods.isreferalclaim().call();

      let referalinfoclaimable = referalinfo.referalIncome;
      referalinfoclaimable = referalinfoclaimable / 1000000;
      if (referalinfoclaimable > 0 && isReferalClaimOpen == true) {
        setClaimable(referalinfoclaimable);
      }

      let referalinfoclaimedusdt = referalinfo.Claimed;
      referalinfoclaimedusdt = referalinfoclaimedusdt / 1000000;

      setReferalInfo(referalinfoclaimable);
      setreferalinfoclaimed(referalinfoclaimedusdt);
    }
    let stagePrice = await contractOf.methods.stagePrice().call();
    stagePrice = webSupply.utils.fromWei(stagePrice.toString());
    setstagePrice_show(stagePrice);
    let isreferalclaim = await contractOf.methods.isreferalclaim().call();
    setIsReferalClaim(isreferalclaim);
  };

  const claim = async () => {
    try {
      console.log("getdepositsLeng", getdepositsLeng);
      setspinner(true);
      const { request } = await prepareWriteContract({
        address: PresaleEthV2_Address,
        abi: PresaleEthV2_ABI,
        functionName: "claimMultiple",
        args: [getdepositsLeng],
        account: address,
      });
      const { hash } = await writeContract(request);
      const data = await waitForTransaction({
        hash,
      });
      toast.success("Claim Successfully");
      setspinner(false);
    } catch (error) {
      setspinner(false);
      toast.error("Something went wrong");

      console.log(error);
    }
  };

  const claimReferalRewward = async () => {
    try {
      setclaimSpinner(true);
      const { request } = await prepareWriteContract({
        address: PresaleEthV2_Address,
        abi: PresaleEthV2_ABI,
        functionName: "claimreferalrewward",
        args: [],
        account: address,
      });
      const { hash } = await writeContract(request);
      const data = await waitForTransaction({
        hash,
      });
      toast.success("Claim Reward Successfully");
      setclaimSpinner(true);
    } catch (error) {
      toast.error("Something went wrong");

      setclaimSpinner(false);
      console.log(error);
    }
  };

  useEffect(() => {
    deposits();
    if (address) {
      setRefAddress(`${history.origin}/buy_token?ref=${address}`);
    } else {
      setRefAddress("Connect wallet");
    }
    setInterval(() => {
      setCopied(false);
    }, 3000);
  }, [address, copied]);

  return (
    <div className="main_profile_page_here">
    <div className="overlay1"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-4 z_upper_full">
            <div className="profle_upper_box">
              <h4 className="">Profile</h4>
              <div className="profile_content gap-3 d-flex align-items-center">
                <div>
                  <img src={pro_img} alt="" />
                </div>
                <div className="pro_right_cont">
                  <p className="mb-0 d-flex">
                    <span className="me-2">
                      {address
                        ? `${address?.substring(0, 6)}...${address?.substring(
                            address?.length - 4
                          )}`
                        : "Connect Wallet"}
                    </span>
                    <CopyToClipboard
                      text={address}
                      onCopy={() => setCopied(true)}
                    >
                      <span className="me-2">
                        {copied ? (
                          <>
                            <FaCopy />
                            COPIED
                          </>
                        ) : (
                          <FaCopy />
                        )}
                      </span>
                    </CopyToClipboard>
                  </p>
                  <a href=""> Enter your email to complete the profile data</a>
                  <button
                    className="buy_token mt-3 d-flex align-items-center"
                    style={{ background: "white", color: "#4f78ff" }}
                  >
                    {" "}
                    <BiSolidMessageSquare /> Send Feedback
                  </button>
                  <Link to="/LogIn">
                    <button
                      className="buy_token mt-3 d-flex align-items-center"
                      style={{ background: "white", color: "#4f78ff" }}
                    >
                      DashBoard
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 z_upper_full  mt-3 mt-md-0">
            <div className="profle_upper_box">
              <h4>Current balance</h4>
              <div className="d-flex gap-3 mt-2 mt-md-0 cn_ssm align-items-center">
                <div className="bout_blace">
                  <p>Total $INFAI</p>
                  <div className="bg_bl">
                    <img src={site} alt="" />
                    <h6>{totalMetaAmount}</h6>
                  </div>
                </div>
                <div className="bout_blace">
                  <p>Current rate</p>
                  <div className="bg_bl">
                    <img src={gold} alt="" />
                    <h6>{stagePrice_show} $</h6>
                  </div>
                </div>
                <div className="bout_blace">
                  <p>Spent in USDT</p>
                  <div className="bg_bl">
                    {/* <img src={site} alt="" /> */}
                    <h6>{usdtspent} $</h6>
                  </div>
                </div>
              </div>
              <div className="bln_btn mt-4 d-flex justify-content-center gap-3">
                <Link to="/buy_token">
                  <button
                    className="buy_token"
                    style={{ background: "white", color: "#4f78ff" }}
                  >
                    {" "}
                    Buy Token
                  </button>
                </Link>
                <button
                  className="buy_token d-flex align-items-center"
                  style={{ background: "white", color: "#4f78ff" }}
                  onClick={claim}
                >
                  {spinner ? (
                    "Loading..."
                  ) : (
                    <>
                      Claim <FaCircleInfo className="mx-1" />
                    </>
                  )}
                </button>
              </div>
              <div className="lower_bnl d-flex  gap-2 align-items-center">
                <div className="enteries">
                  <h6>Your entries</h6>
                  <h4>{depositenteries}</h4>
                </div>
                <div className="text_bln">
                  <p>
                    To get more entries and higher chances of winning buy $50 in
                    $INFAI. Every $50 of $INFAI tokens gives you +10 entry with no
                    limits.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row mt-3">
          <div className="profile_data res_sm">
            <h5>Profile data is not complete. Enter Your email</h5>
            <div className="d-flex gap-2">
              <input
                type="email"
                placeholder="Enter Your Email"
                className="data_in"
                name=""
                id=""
              />
              <button className="buy_token">Send</button>
            </div>
          </div>
        </div> */}
        {/* <div className="row mt-3">
          <div className="profle_upper_box">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="acc_headre">
                  $INFAI transaction (0)
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-3">
                      {" "}
                      <div className="accc_box">Tokens</div>
                    </div>
                    <div className="col-md-3">
                      {" "}
                      <div className="accc_box">Bonus</div>
                    </div>
                    <div className="col-md-3">
                      {" "}
                      <div className="accc_box">Chain</div>
                    </div>
                    <div className="col-md-3">
                      {" "}
                      <div className="accc_box">Purchase amount</div>
                    </div>
                  </div>
                  <p className="text-white mt-3">
                    You haven't transactions yet
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div> */}
        <div className="row mt-3">
          <div className="profle_upper_box position-relative z_upper_full">
            <h4>Earn Up to a 15% Bonus with You Referral Link</h4>
            <div className="d-flex mt-2  gap-2">
              <div className="reff_link">
                <p className="mb-0 text-truncate">
                  {refAddress}
                  {/* https://scapesmania.io/ref/2050D */}
                  {/* value={refAddress} */}
                </p>
              </div>
              <CopyToClipboard text={refAddress} onCopy={() => setCopied(true)}>
                <button
                  className="buy_token d-flex align-items-center"
                  style={{ background: "white", color: "#4f78ff" }}
                >
                  <FaCopy />{" "}
                  <span className="mx-1">{copied ? "COPIED" : "COPY"} </span>
                </button>
              </CopyToClipboard>
            </div>
            <p className="text-white mt-4">
              Share your referral link with your friends or followers and get up
              to a 15% bonus when they purchase $INFAI using your link
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="profle_upper_box z_upper_full">
            <div className="row align-items-center       ">
              <div className="col-md-3">
                {" "}
                <h6 className="text-white">Total earned</h6>
                <div className="accc_box d-flex align-items-center">
                  <img src={sfa} alt="" /> {referalInfo} USDT
                </div>
              </div>
              <div className="col-md-3 mt-3 mt-md-0">
                <h6 className="text-white">Claimed</h6>{" "}
                <div className="accc_box">{referalinfoclaimed} USDT</div>
              </div>
              <div className="col-md-3 mt-3 mt-md-0">
                <h6 className="text-white">Ready for claim</h6>{" "}
                <div className="accc_box">{claimable} USDT</div>
              </div>
              <div className="col-md-3 mt-4">
                <button
                  className="buy_token"
                  style={{
                    background: "white",
                    color: "#4f78ff",
                    cursor: isReferalClaim ? "pointer" : "not-allowed",
                  }}
                  disabled={isReferalClaim ? false : true}
                  onClick={claimReferalRewward}
                >
                  {claimSpinner ? "Loading..." : "claim"}
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
